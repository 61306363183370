import { Field, ListField, ObjectForm, ScalarField, SelectField, Updateable } from './model';
import { settings } from '../../areas/main/config';
import appUrl from '../../util/appUrl';
import { format } from '../../util/format';
import { parseJson } from '../../util/parse';
import { setQueryParameter } from '../../util/queryString';
import { dismissModal, showModalPage } from '../dialog/modal';
import { getCurrentPopup } from '../dialog/popup';
import { confirm } from '../notify';
import jQuery from 'jquery';
import * as Cookie from '../../util/cookie';
import _ from 'lodash';

export interface Instruction {
	opCode: number;
	targetSpecifier: string;
	operands: string[];
}

export class Engine {
	form: ObjectForm;

	constructor(form: ObjectForm) {
		this.form = form;
	}

	run(context: string, argument: string, source: Updateable, success?: () => void, aborted?: () => void) {
		if (!this.form.activeEnabled) return;

		source.isUpdating(true);
		source.updateError(null);

		jQuery
			.post(
			appUrl(this.form.activeUrl + '?context=' + context + '&argument=' + argument),
			this.snapshot()
			)
			.done((result, status, xhr) => {
				if (_.isArray(result)) {
					var machine = new Machine(this);
					machine.run(result, success, aborted);
				}
			})
			.fail((xhr, status, error) => {
				this.handleError(source, xhr.responseText);
			})
			.always(() => {
				source.isUpdating(false);
			});
	}

	runOperations(operations: Instruction[]) {
		var machine = new Machine(this);
		machine.run(operations);
	}

	handleError(source: Updateable, message: string) {
		if (message) message = settings.strings.activeFormError + '\n' + message;
		source && source.updateError(message);
		console.error(message);
	}

	resolve(target: string): Field {
		return this.form.findField(target);
	}

	snapshot() {
		var map = {};
		this.form.snapshot(map);
		return map;
	}
}

interface Operation {
	name: string;
	requiresTarget?: boolean;
	requiresOperands?: boolean;
	isAsync?: boolean;
	execute?: (machine: Machine) => void;
}

class Machine {
	static operations: Operation[] = [
		//0
		{
			name: 'noop'
		},

		//1
		{
			name: 'test',
			requiresTarget: true,
			requiresOperands: true
		},

		//2
		{
			name: 'call',
			requiresOperands: true,

			execute: function (m) {
				var f: Function = window.self[m.operands[0]];

				if (_.isFunction(f)) {
					f.apply(
						(m.target || window.self),
						m.operands.slice(1)
					);
				}
				else {
					m.setError('Can not find a function named "{0}"', m.operands[0]);
				}
			}
		},

		//3
		{
			name: 'set',
			requiresTarget: true,

			execute: function (m) {
				if (m.operands.length > 0) {
					(<ScalarField>(m.target)).parse(parseJson(m.operands[0]));
				}
				else {
					m.target.value(null);
				}
			}
		},

		//4
		{
			name: 'eval',
			requiresOperands: true,

			execute: function (m) {
				eval(m.operands[0]);
			}
		},

		//5
		{
			name: 'add',
			requiresTarget: true,
			requiresOperands: true
		},

		//6
		{
			name: 'remove',
			requiresTarget: true,
			requiresOperands: true
		},

		//7
		{
			name: 'hint',
			requiresOperands: true,

			execute: function (m) {
				if (m.target) {
					m.target.message(m.operands[0]);
				}
				else {
					m.engine.form.message(m.operands[0]);
				}
			}
		},

		//8
		{
			name: 'show',
			requiresTarget: true,

			execute: function (m) {
				m.target.isVisible(true);
			}
		},

		//9
		{
			name: 'hide',
			requiresTarget: true,

			execute: function (m) {
				m.target.isVisible(false);
			}
		},

		//10
		{
			name: 'open',
			requiresOperands: true,

			execute: function (m) {
				var url: string = m.operands[0];

				//operands 1 and 2 represent with and height, they are currently unused
				var hideCloseButton: boolean = m.intOperand(3, 0) > 0;

				var callback = function (program: Instruction[], context) {
					if (program) {
						m.run(program);
					}
				}

				showModalPage({
					url: url,
					callback: callback,
					hideCloseButton: hideCloseButton
				});

				m.stop();
			}
		},

		//11
		{
			name: 'disable',
			requiresTarget: true,

			execute: function (m) {
				m.target.isDisabled(true);
			}
		},

		//12
		{
			name: 'require',
			requiresTarget: true,

			execute: function (m) {
				m.target.isRequired(true);
			}
		},

		//13
		{
			name: 'enable',
			requiresTarget: true,

			execute: function (m) {
				m.target.isDisabled(false);
			}
		},

		//14
		{
			name: 'invalidate',
			requiresOperands: true,

			execute: function (m) {
				const message = m.operands[0];

				if (m.target) {
					m.target.customError(message);
				}
				else {
					m.errors.push(message);
				}
			}
		},

		//15
		{
			name: 'omit',
			requiresTarget: true,
			execute: function (m) {
				m.target.isRequired(false);
			}
		},

		//16
		{
			name: 'dismiss',

			execute: function (m) {
				if (m.target) {
					m.target.dismissMessage();
				}
				else {
					m.engine.form.dismissMessage();
				}
			}
		},

		//17
		{
			name: 'populate',
			requiresTarget: true,
			requiresOperands: true,

			execute: function (m) {
				var data = $.map(parseJson(m.operands[0]), (item: any) => {
					return {
						id: item.value,
						text: item.text
					}
				});

				var field: SelectField = <SelectField>m.target;
				field.options(data);
			}
		},

		//18
		{
			name: 'reloadList',

			execute: function (m) {
				if (m.target) {
					var field: ListField = <ListField>m.target;
					field.load();
				}
				else {
					for (let f of m.engine.form.fields.list) {
						if (f.initialized()) {
							f.load();
						}
					};
				}
			}
		},

		//19
		{
			name: 'close',

			execute: function (m) {
				var p = getCurrentPopup();

				//build continuation for parent window
				var continuation = m.program.slice(m.currentIndex);

				if (p) {
					var argument = null;
					if (continuation.length) {
						console.log("After the close, %i operations will continue on the parent window", continuation.length);
						argument = continuation;
					}
					else {
						argument = m.operands.length && m.operands[0];
					}
					p.callbackArgument = argument;
				}
				else {
					if (continuation.length > 0) {
						console.log("Unable to locate parent window to execute %i operations", continuation.length);
					}
					else {
						console.log("Unable to locate parent window");
					}
				}

				dismissModal();
				m.stop();
			}
		},

		//20
		{
			name: 'revalidate',
			requiresTarget: true,

			execute: function (m) {
				m.target.customError(null);
			}
		},

		//21
		{
			name: 'relocate',
			requiresOperands: true,

			execute: function (m) {
				let [url, message] = m.operands;

				if (message) {
					Cookie.set(
						settings.cookieNames.workflowMessage,
						message,
						{ path: settings.applicationPath }
					);
				}

				m.abort(appUrl(url));
			}
		},

		//22
		{
			name: 'confirm',
			requiresTarget: true,
			requiresOperands: true,
			isAsync: true,

			execute: function (m) {
				m.confirm(JSON.parse(m.operands[0])).then(result => {
					(<ScalarField>m.target).parse(result);
					m.continue();
				});
			}
		},

		//23
		{
			name: 'setQueryStringParameter',
			requiresOperands: true,

			execute: function (m) {
				setQueryParameter(
					JSON.parse(m.operands[0]),
					JSON.parse(m.operands[1])
				);
			}
		},

		//24
		{
			name: 'promptContinue',
			requiresOperands: true,
			isAsync: true,

			execute: function (m) {
				m.confirm(JSON.parse(m.operands[0])).then(proceed => {
					if (proceed) {
						m.continue();
					}
					else {
						m.abort();
					}
				});
			}
		},

		//25
		{
			name: 'abort',

			execute: function (m) {
				const message = m.operands[0];

				if (message) {
					m.errors.push(message);
				}

				m.abort();
			}
		}
	];

	target: Field;
	operands: string[];
	program: Instruction[]
	currentIndex: number;
	private isStopped: boolean;
	private errors: string[];
	private onSuccess: () => void;
	private onAbort: (redirectUrl?: string) => void;

	constructor(public engine: Engine) { }

	run(program: Instruction[], onSuccess?: () => void, onAbort?: (redirectUrl?: string) => void) {
		this.onSuccess = onSuccess;
		this.onAbort = onAbort;

		if (program.length) {
			console.debug("Running active forms", program);
			this.isStopped = false;
			this.program = program;
			this.errors = [];
			this.currentIndex = 0;
			this.continue();
		}
		else {
			this.stop();
		}
	}

	continue() {
		if (this.isStopped) {
			return;
		}
		else if (this.currentIndex < this.program.length) {
			var instruction = this.program[this.currentIndex++];
			this.execute(instruction);
		}
		else {
			this.stop();
		}
	}

	confirm(prompt: string): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			var form = this.engine.form;
			var isSaving = form.isSaving();
			form.isSaving(false);

			confirm(prompt).then(result => {
				form.isSaving(isSaving);

				resolve(result);
			});
		});
	}

	abort(redirectUrl?: string) {
		this.isStopped = true;
		this.validate();

		if (this.onAbort) {
			this.onAbort(redirectUrl);
		}
		else if (redirectUrl) {
			location.href = redirectUrl;
		}
	}

	stop() {
		this.isStopped = true;
		this.validate();

		this.onSuccess && this.onSuccess();
	}

	validate() {
		if (!_.isEmpty(this.errors)) {
			let form = this.engine.form;
			form.errors.push.apply(form.errors, this.errors);
			form.validate(true);
		}
	}

	execute(instr: Instruction) {
		if (instr.opCode < 0 || instr.opCode >= Machine.operations.length) {
			this.setError('bad instruction; invalid opcode {0}', instr.opCode);
			return this.continue();
		}

		var op = Machine.operations[instr.opCode];

		if (!_.isFunction(op.execute)) {
			this.setError('bad instruction; {0} is not implemented', op.name);
			return this.continue();
		}

		this.target = null;
		if (instr.targetSpecifier) {
			this.target = this.engine.resolve(instr.targetSpecifier);
		}

		if (op.requiresTarget && !this.target) {
			this.setError('bad instruction; {0} requires a target, none could be located using: {1}', op.name, instr.targetSpecifier);
			return this.continue();
		}

		if (op.requiresOperands && instr.operands.length == 0) {
			this.setError('bad instruction; {0} requires one or more operands, none were supplied', op.name);
			return this.continue();
		}

		this.operands = instr.operands;

		try {
			op.execute(this);
		}
		catch (e) {
			this.setError(e.message);
		}
		finally {
			if (!op.isAsync) {
				this.continue();
			}
		}
	}

	private intOperand(index: number, zeroValue: number) {
		if (this.operands.length < index) return zeroValue;

		var s = this.operands[index];
		if (!s) return zeroValue;

		var n = parseInt(s);
		if (isNaN(n)) return zeroValue;

		if (n == 0) return zeroValue;
		else return n;
	}

	setError(message: string, ...args: any[]) {
		args.unshift(message);
		this.engine.handleError(this.target, format.apply(null, args));
	}
}
