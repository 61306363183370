export default class BoundObject {
	constructor() {
		function prototypeChainOf(instance: object): object[] {
			var result = [];
			var prototype = Object.getPrototypeOf(instance);

			while (prototype.constructor !== BoundObject) {
				result.push(prototype);
				prototype = Object.getPrototypeOf(prototype);
			}

			//most distant ancestor first to support overriding
			return result.reverse();
		}

		for (let p of prototypeChainOf(this)) {
			for (let [k, v] of Object.entries(p)) {
				if (typeof v === 'function' && k !== 'constructor') {
					this[k] = v.bind(this);
				}
			}
		}
	}
}
