export enum FormState {
	Added = 'added',
	Modified = 'modified',
	Removed = 'removed',
	Unmodified = 'unmodified'
}

export enum ListDisplayType {
	Grid = 'Grid',
	Gantt = 'Gantt',
	TaskBoard = 'TaskBoard'
}