import jQuery from 'jquery';
import { settings } from '../../../areas/main/config';
import { warning } from '../../notify';

export default function parseExcelHtml(html: string): Array<Array<string>> {
	const rows = jQuery(html).find('tbody tr').toArray();

	let rowCount = rows.length;

	if (rowCount > 0) {
		let result = new Array<Array<string>>();

		rows.forEach((row, y) => {
			const columnValues = getColumnValues(row);
			let rowValues = [];

			columnValues.forEach((value) => {
				rowValues.push(value);
			});

			result.push(rowValues);
		});

		return result;
	}
	else {
		warning(settings.strings.pasteRowsNoClipboardData);
		return null;
	}

	function getColumnValues (row: HTMLElement) : string[] {
		let columnValues = new Array<string>();

		jQuery(row).children().each((_, col: HTMLTableCellElement) => {
			const value = jQuery(col).text()
				.trim()
				// excel may split text values across multiple lines in the html
				.replace(/[\r\n]\s*/, " ")

			columnValues.push(value);

			for (let i = 1; i < col.colSpan; i++) {
				columnValues.push(null);
			}
		});

		return columnValues;
	}
}
