import _ from 'lodash';
import $ from 'jquery';
import { error as notifyError } from '../notify';
import { parseClientModel } from '../../util/parse';
import { settings } from '../../areas/main/config';
import { TreeView, TreeViewContent, TreeViewItem } from './Model';
import './style.less';

export async function bind(context?: JQuery) {
	let $elements = $('.tree-container', context);

	if ($elements.length > 0) {
		await import(/* webpackChunkName: "kendo-dataviz" */ '../../plugins/kendo/dataviz');

		$elements.each((_, container) => {
			let model = parseClientModel($('.tree-model', container)) as TreeView;
			new Tree(container, model);
		});
	}
}

class Content {
	items: TreeViewItem[];
	itemsById: { [index: string]: TreeViewItem; } = {};

	constructor(content: TreeViewContent) {
		this.items = content && content.root
			? content.hideRoot
				? content.root.childItems ?? []
				: [content.root]
			: [];

		this.index(this.items);
	}

	index(items: TreeViewItem[]) {
		for (let item of items) {
			this.itemsById[item.id] = item;

			if (item.childItems) {
				this.index(item.childItems);
			}
		}
	}
}

export class Tree {
	private datasource: kendo.data.HierarchicalDataSource;
	private hasExpanded = false;
	private treeView: JQuery<HTMLElement>;
	private content: Content;
	private filterExpandThreshold: number = 50;
	private filterTimer = 0;

	constructor(private container: Element, private model: TreeView) {
		if (model.content) {
			this.applyLimitWarning(model.content);

			if (model.expandAllUrl) {
				$(this.container).addClass('with-controls');
			}
		}
		else {
			$(this.container).addClass('with-no-content');
			return;
		}

		this.content = new Content(model.content);

		this.datasource = new kendo.data.HierarchicalDataSource({
			transport: {
				//custom binding is required to combine both local and load on demand content
				read: options => this.readContent.call(this, options),
			},
			schema: {
				model: {
					id: "id"
				}
			}
		});

		this.treeView = $('.tree', container).kendoTreeView({
			dataUrlField: 'notused',
			loadOnDemand: true,
			template: kendo.template($('.tree-template', container).html()),
			dataSource: this.datasource,
			select: function (e) {
				e.preventDefault();
			},
			dataBound: () => {
				$('.no-filter-results', container).toggleClass('hide', this.datasource.data().length > 0)
			}
		});

		this.treeView.on("click", "a.select", e => {
			this.getTreeViewModel().select($(e.target));
		});

		$('.btn-expand-all', container).on("click", e => {
			e.preventDefault();
			this.expandAll();
		});

		$('.filter-text', container).on('input', e => {
			clearTimeout(this.filterTimer);
			this.filterTimer = window.setTimeout(
				() => {
					let query = $(e.target).val().toString();
					this.filter(query);
				},
				300
			);
		});
	}

	private expandAll() {
		if (this.hasExpanded) {
			return;
		}
		else {
			this.treeView.mask(settings.strings.loadingMessage);

			const selectedUrl = this
				.getTreeViewModel().select()
				.find('a.item-navigation')
				.attr('href');

			$.ajax({
				url: this.model.expandAllUrl,
				data: {
					target: this.model.linkTarget
				},
				success: (content: TreeViewContent) => {
					if (content) {
						this.applyLimitWarning(content);

						this.content = new Content(content);
						this.datasource.read();
						this.hasExpanded = true;

						if (selectedUrl) {
							this.getTreeViewModel().select(
								this.treeView.find(`a.item-navigation[href='${selectedUrl}']`).closest('.k-item')
							);
						}

						$('.btn-expand-all', this.container).css({
							visibility: 'hidden'
						});
					}
				},
				error: () => {
					notifyError(settings.strings.treeLoadError);
				},
				complete: () => {
					this.treeView.unmask();
				}
			});
		}
	}

	private readContent(options) {
		const id: string = options.data.id;

		if (!id) {
			//if id is not specified, the treeview is requesting the root items
			options.success(this.content.items ?? []);
			return;
		}

		//the treeview is requesting the child items for the node with the specifed id
		//these are either already available in local content or require loading via the expandUrl
		const item = this.content.itemsById[id];

		if (!item) {
			options.success([]);
			return;
		}

		if (item.childItems) {
			options.success(item.childItems);
			return;
		}

		const url = item.expandUrl;

		if (url) {
			$.ajax({
				url: url,
				data: {
					target: this.model.linkTarget
				},
				success: (content: TreeViewContent) => {
					this.applyLimitWarning(content);

					let result = content.root.hasChildren ? content.root.childItems : [];
					this.content.index(result);

					options.success(result);
				},
				error: function (error) {
					notifyError(settings.strings.treeLoadError);
					options.error(error);
				}
			});
		}
		else {
			options.success([]);
		}
	}

	private getTreeViewModel(): kendo.ui.TreeView {
		return this.treeView.data("kendoTreeView");
	}

	private applyLimitWarning(content: TreeViewContent) {
		if (content.atOrAboveLimit) {
			$(this.container).addClass('with-limit-warning');
		}
	}

	private filter(query: string): void {
		if (query === '') {
			this.content = new Content(this.model.content);
			this.datasource.read();
			return;
		};

		query = query.toLowerCase();

		let content = $.extend(true, {}, this.model.content);
		let items = this.filterItems(content.root.childItems, query);

		if (this.count(items) <= this.filterExpandThreshold) {
			this.expandMatchedItems(items);
		}

		content.root.childItems = items;

		this.content = new Content(content);
		this.datasource.read();
	}

	private expandMatchedItems(data: TreeViewItem[]): void {
		_.forEach(data, (item: TreeViewItem) => {
			item.expanded = this.hasMatch(item.childItems);
			this.expandMatchedItems(item.childItems);
		});
	}

	private filterItems(data: TreeViewItem[], query: string, parentIsMatch: boolean = false): TreeViewItem[] {
		if (query === '') {
			return data;
		}

		let items: TreeViewItem[];

		_.forEach(data, (item: TreeViewItem) => {
			item.isMatch = item.text.toLowerCase().indexOf(query) >= 0;
			const isMatch = item.isMatch || parentIsMatch;
			item.childItems = this.filterItems(item.childItems, query, isMatch);

			if (isMatch || !_.isUndefined(item.childItems)) {
				if (_.isUndefined(items)) {
					items = [];
				}

				items.push(item);
			}
		});

		return items;
	}

	private hasMatch(items: TreeViewItem[]): boolean {
		return _.some(items, (item: TreeViewItem) => {
			return item.isMatch === true || this.hasMatch(item.childItems);
		});
	}

	private count(data: TreeViewItem[]): number {
		if (_.isUndefined(data)) {
			return 0;
		}

		let length = data.length;

		_.forEach(data, (item: TreeViewItem) => {
			length += this.count(item.childItems);
		});

		return length;
	}
}
