import _ from 'lodash';
import appUrl from '../../util/appUrl';
import BoundObject from '../../util/BoundObject';
import jQuery from 'jquery';
import ko, { Computed, Observable, ObservableArray, Subscribable, Subscription } from 'knockout';
import koDeferredComputed from '../../util/koDeferredComputed';
import parseExcelHtml from './grid/parseExcelHtml';
import { clear as clearUserFilter, save as saveUserFilter, UserFilterType } from '../../components/form/filter/UserFilter';
import { Engine, Instruction } from './active';
import { error as showError } from '../notify';
import { findPopup, getInputArgument } from '../dialog/popup';
import { format, isEmptyWhenZeroFormat, toString } from '../../util/format';
import { handle as handleQueryChange } from '../../areas/main/events/queryChanged';
import { parseIso8601Date, parseJson } from '../../util/parse';
import { setQueryParameters } from '../../util/queryString';
import { settings } from '../../areas/main/config';
import { showModalPage } from '../dialog/modal';
import { FormState, ListDisplayType } from './model-types';

export interface IFilterable {
	setFilterValue(name: string, value: any): void;
	applyFilter(): void;
}

export interface Updateable {
	isUpdating: Observable<boolean>;
	updateError: Observable<string>;
}

export interface Field extends Updateable, ExpressionLookupContext {
	id: string;
	modelId: Subscribable<string>;

	label: string;

	value: Observable<any>;
	originalValue?: any;
	form: ObjectForm;
	editMode: boolean;
	isValid: Subscribable<boolean>;
	error: Subscribable<string>;
	customError?: Observable<string>;
	hasChanges: Subscribable<boolean>;
	isVisible: Subscribable<boolean>;
	isDisabled: Subscribable<boolean>;
	isRequired: Subscribable<boolean>;

	message?: Observable<string>;

	linksDisabled: boolean;
	computedValueTargetsParent: boolean;

	revert();
	dismissMessage?: () => void;
	focus: () => void;
	onFocus: () => void;
	onBlur: () => void;
	validate(): boolean;
}

export interface Command {
	uniqueId?: string;
	command?: string;
	argument?: any;
	isAsync?: boolean;
	isPartial?: boolean;
	shouldCancel?: boolean;
	contextClass?: string;
	iconClass?: string;
	proceed: (form: Form) => void;
	openInNewWindow?: boolean;
	includeHash?: boolean;
}

interface ExpressionSite extends Updateable {
	id: string;
	expressionsEnabled: Subscribable<boolean>;
	expressionError: Observable<string>;
	getGlobals(): ExpressionGlobals;
}

interface ExpressionLookupContext extends ExpressionSite {
	form: Form;
	whenIdle: (callback: () => any) => any;
	pauseExpressions?: Observable<boolean>;
}

class ExpressionLookup {
	static whenIdle = function(isBusy: Subscribable<boolean>, callback: () => any, exclusiveLock?: any) {
		ko.tasks.runEarly();

		if (exclusiveLock && this.pending[exclusiveLock]) {
			return;
		}

		if (isBusy()) {
			exclusiveLock && (this.pending[exclusiveLock] = true);

			var s = isBusy.subscribe(value => {
				if (!value) {
					s.dispose();
					callback();

					exclusiveLock && (delete this.pending[exclusiveLock]);
				}
			});

			return null;
		}
		else {
			return callback();
		}
	}

	static pending = {};
}

class ExpressionGlobals {
	constructor(private context: ExpressionLookupContext) { }

	lookup = (target: string) => {
		const form = this.context.form;

		if (form instanceof ObjectForm) {
			var index = target.indexOf(".");
			var property = '';

			if (index >= 0) {
				if (index < target.length) {
					property = target.slice(index + 1);
				}

				target = target.slice(0, index);
			}

			return form.lookup(this.context, target, property);
		}
	}
}

class CommandButton extends BoundObject {
	uniqueId: string;
	label: string;
	tooltip: string;
	command: string;
	argument: any;
	isAsync: boolean;
	shouldCancel: boolean;
	openInNewWindow: boolean;
	contextClass: string;
	iconClass: string;
	confirmation: string;
	execute: (command: Command) => void;
	isDisabled: Subscribable<boolean>;
	isVisible: Subscribable<boolean>;
	effectivelyDisabled: Computed<boolean>;

	constructor(data: any, form: Form) {
		super();

		this.uniqueId = data.uniqueId || null;
		this.label = data.label || null;
		this.tooltip = data.tooltip || null;
		this.command = data.command || null;
		this.argument = data.argument || null;
		this.openInNewWindow = data.openInNewWindow || null;
		this.isAsync = data.isAsync || data.openInNewWindow || null;
		this.shouldCancel = data.shouldCancel || null;
		this.contextClass = data.contextClass || null;
		this.iconClass = data.iconClass || null;
		this.confirmation = data.confirmationMessage || null;
		this.execute = form.save;
		this.isDisabled = ko.observable(false);
		this.isVisible = ko.observable(true);
		this.effectivelyDisabled = ko.computed(() => this.isDisabled() || !this.isVisible());
	}
}

class ObjectCommandButton extends CommandButton implements ExpressionSite {
	id: string;
	isUpdating: Observable<boolean>;
	updateError: Observable<string>;
	isVisibleExpression: string;
	isDisabledExpression: string;
	expressionError = ko.observable('');
	expressionsEnabled: Subscribable<boolean>;
	subCommands: CommandButton[] = [];

	constructor(data: any, public form: ObjectForm) {
		super(data, form);

		this.expressionsEnabled = form.enableExpressions;

		this.isVisibleExpression = data.isVisibleExpression || null;
		this.isVisible = form.initialiseStatusExpression(this, "isVisible", this.isVisibleExpression, this.isVisible);

		this.isDisabledExpression = data.isDisabledExpression || null;
		this.isDisabled = form.initialiseStatusExpression(this, "isDisabled", this.isDisabledExpression, this.isDisabled);

		this.effectivelyDisabled = ko.computed(() => this.isDisabled() || !this.isVisible());

		data.subCommands && $.each(data.subCommands, (i, c) => {
			this.subCommands[i] = new ObjectCommandButton(c, form);
		});
	}

	whenIdle(callback: () => any) {
		return ExpressionLookup.whenIdle(this.isUpdating, callback);
	}

	getGlobals(): ExpressionGlobals {
		return new ExpressionGlobals(this);
	}
}

class Decision {
	message: string;
	outcomes: CommandButton[] = [];

	constructor(data: any, form: Form) {
		this.message = data.message || null;
		data.outcomes && $.each(data.outcomes, (i, c) => {
			this.outcomes[i] = new CommandButton(c, form);
		});
	}
}

export interface ValidationItem {
	label: string;
	message: string;
	focus: () => void;
}

export function getRootForm() {
	return Form.root;
}

export class Form extends BoundObject {
	static root: Form;

	static unloadWarning = true;

	isValid: Subscribable<boolean>;
	validationSummary: ObservableArray<any>;
	errors = ko.observableArray<string>();

	hasChanges: Subscribable<boolean>;
	isBusy: Subscribable<boolean>;
	isSaving: Observable<boolean>;

	editMode: boolean;
	canEdit: boolean;
	linksDisabled: boolean;

	message = ko.observable('');

	commands: CommandButton[] = [];
	decision: Decision;

	returnUrl: string;

	commandBindingTriggers: Record<string, (() => void)> = {}

	save(command: Command) {
		if (command.shouldCancel == true) {
			this.cancel(command);
			return;
		}

		if (this.isSaving()) return;

		this.isSaving(true);
		ko.tasks.runEarly();

		if (!this.isBusy()) {
			this.commit(command);
		}
		else {
			var s = this.isBusy.subscribe(() => {
				if (!this.isBusy()) {
					this.commit(command);
					s.dispose();
				}
			});
		}
	}

	commit(command: Command) {
		this.errors.removeAll();
		this.validate();
		if (this.isValid()) {
			command.proceed(this);
			if (command.isAsync) {
				this.isSaving(false);
			}
		}
		else {
			this.isSaving(false);
		}
	}

	revert() {
	}

	cancel(command: Command) {
		this.suppressUnloadWarning();
		command.proceed(this);
	}

	command(command: Command) {
		command.proceed(this);
	}

	suppressUnloadWarning(): boolean {
		Form.unloadWarning = false;
		return true;
	}

	tryConfirmHasChanges(): boolean {
		if (!Form.unloadWarning || !this.hasChanges()) {
			return true;
		}

		return confirm(settings.strings.unsavedChangesWarning);
	}

	isPopup(): boolean {
		return (findPopup(window.self) != null);
	}

	buildSummary(): ValidationItem[] {
		var items: ValidationItem[] = [];

		_.each(this.errors(), error => {
			items.push({
				label: null,
				focus: null,
				message: error
			});
		});

		return items;
	}

	validate(): boolean {
		this.validationSummary(this.buildSummary());
		return this.validationSummary().length == 0;
	}

	applyErrors(errors: { [index: string]: string; }): boolean {
		var hasErrors = !_.isEmpty(errors);
		var value = [];

		var e = errors[''];
		if (e) {
			value.push(e);
			errors[''] = null;
		}

		_.each(errors, (v, k?) => {
			v && value.push('(' + k + ') ' + v);
		});

		if (hasErrors) {
			this.errors(value);
			this.validate();
		}

		return hasErrors;
	}

	dismissMessage() {
		this.message(null);
	}

	registerCommandTrigger(name: string, trigger: () => void) {
		this.commandBindingTriggers[name] = trigger;
	}

	triggerCommand(name: string) {
		const trigger = this.commandBindingTriggers[name];
		if (trigger) {
			trigger();
		}
	}

	constructor(data: any, public context?: any, hasChanges?: Subscribable<boolean>, isBusy?: Subscribable<boolean>) {
		super();

		this.canEdit = _.isBoolean(data.canEdit) ? data.canEdit : true;
		this.editMode = data.editMode || false;

		this.validationSummary = ko.observableArray(this.buildSummary());
		this.isValid = ko.computed(() => this.validationSummary().length == 0);

		this.hasChanges = hasChanges || ko.observable(false);
		this.isBusy = isBusy || ko.observable(false);
		this.isSaving = ko.observable(false);

		this.decision = data.decision ? new Decision(data.decision, this) : null;

		this.linksDisabled = (data.linksDisabled === true);

		if (_.isBoolean(data.unloadWarning)) {
			Form.unloadWarning = data.unloadWarning;
		}
		else {
			Form.unloadWarning = true;
		}
	}
}

interface Entity {
	errors?: { [id: string]: string; };
	values: { [id: string]: any; };
}

class FieldLookup {
	all: Field[] = [];
	map: { [index: string]: Field; } = {};
	user: Field[] = [];
	scalar: ScalarField[] = [];
	list: ListField[] = [];

	public add<T extends Field>(id: string, field: T) {
		this.all.push(field);
		this.map[id] = field;

		if (!ObjectForm.isMetaField(id)) {
			this.user.push(field);
		}

		if (field instanceof ScalarField) {
			this.scalar.push(field);
		}

		if (field instanceof ListField) {
			this.list.push(field);
		}

		return field;
	}
}

export class ObjectForm extends Form implements Updateable {
	static readonly idProperty: string = "id";

	id: string;
	formId: any;
	key: string;
	parentKey: string;
	dataId: Observable<number>;
	state: Computed<FormState>;
	displayName: string;

	fields: FieldLookup;

	isRemoved: Observable<boolean>;
	isArchived: boolean;

	idProperty: string;
	stateProperty: string;
	modelIdforDataId: Computed<string>;
	modelIdforState: Computed<string>;

	activeViewArgument: string;
	activeViewContext: string;
	activeCreateArgument: string;
	activeCreateContext: string;
	activeEditArgument: string;
	activeEditContext: string;
	activeSaveArgument: string;
	activeSaveContext: string;
	activeCancelArgument: string;
	activeCancelContext: string;
	activeUrl: string;
	activeOperations: Instruction[];
	activeEnabled: boolean;
	enableExpressions: Observable<boolean>;
	engine: Engine;

	isUpdating: Observable<boolean>;
	isSaving: Observable<boolean>;
	updateError: Observable<string>;
	shouldPost: Observable<boolean>;
	isReady = false;

	constructor(data: any, context?: any) {
		super(data, context);

		this.enableExpressions = ko.observable(false);

		if (data.isLocked === true) {
			this.canEdit = false;
		}

		if (this.canEdit && data.displayMode === false) {
			this.editMode = true;
		}

		this.fields = new FieldLookup();

		this.idProperty = data.idProperty || '$Id';
		this.stateProperty = data.stateProperty || '$State';
		this.isArchived = data.isArchived || false;

		this.id = data.id || '';
		this.formId = data.formId;
		this.key = data.key;
		this.parentKey = data.parentKey;
		this.isUpdating = ko.observable(false);
		this.isSaving = ko.observable(false);
		this.isRemoved = ko.observable(false);
		this.updateError = ko.observable<string>(null);
		this.shouldPost = ko.observable(true);

		var errors = data.errors || {};
		var hasErrors = false;

		this.dataId = ko.observable(_.isNumber(data.dataId) ? data.dataId : null);
		this.displayName = data.displayName || '';

		if (data.fields) {
			let listFields = [];

			$.each(data.fields, (id, c) => {
				c.id = id;
				var f: Field = null;

				switch (c.type.toLowerCase()) {
					case 'string':
						f = new StringField(c, this);
						break;
					case 'int':
					case 'float':
						f = new NumberField(c, this);
						break;
					case 'select':
						f = new SelectField(c, this, false);
						break;
					case 'selectmany':
						f = new SelectField(c, this, true);
						break;
					case 'objectselect':
						f = new ObjectSelectField(c, this, false);
						break;
					case 'objectselectmany':
						f = new ObjectSelectField(c, this, true);
						break;
					case 'date':
						f = new DateField(c, this);
						break;
					case 'bool':
						f = new BoolField(c, this);
						break;
					case 'file':
						f = new FileField(c, this);
						break;
					case 'list':
						//we do these last so that expressions in lists work with the parent form
						listFields.push(c);
						return;
					default:
						f = new ScalarField(c, this);
						break;
				}

				this.fields.add(id.toString(), f);
			});

			$.each(listFields, (id, c) => {
				c.list.errors = this.pluckErrors(errors, c.id);
				let f = this.fields.add(c.id, new ListField(c, this));

				if (!f.isValid()) {
					hasErrors = true;
				}

				if (f.initialiseStatusExpressions) {
					f.initialiseStatusExpressions();
				}
			});
		}

		for (let f of this.fields.scalar) {
			if (f.initialiseStatusExpressions) {
				f.initialiseStatusExpressions();
			}

			if (!this.editMode || !f.expression) {
				continue;
			}

			if (f.isDependencyExpression || f.isComputedExpression) {
				//these are initialized after calculated value expressions have fully completed
				continue;
			}

			f.tryInitialiseExpression();
		}

		if (!this.applyErrors(errors) && hasErrors) {
			this.validate(true);
		}

		this.hasChanges = ko.computed(() => {
			for (let f of this.mutableFields()) {
				if (f instanceof ScalarField && f.isCalculated()) {
					continue;
				}

				if (f.hasChanges()) {
					return true;
				}
			}

			return false;
		});

		this.isBusy = ko.computed(() => {
			if (this.isUpdating()) {
				return true;
			}
			else {
				return this.fields.all.some(f => {
					if (f instanceof ListField) {
						return f.editMode && f.isBusy();
					}
					else {
						return f.isUpdating();
					};
				});
			}
		});

		this.state =
			ko.computed(() => {
				if (this.isRemoved())
					return FormState.Removed;
				else if (this.dataId() === null)
					return FormState.Added;
				else if (this.hasChanges() && this.editMode)
					return FormState.Modified;
				else
					return FormState.Unmodified;
			})
			.extend({ notify: 'always' });

		this.modelIdforDataId = koDeferredComputed(() => this.qualifyModelId(this.idProperty));
		this.modelIdforState = koDeferredComputed(() => this.qualifyModelId(this.stateProperty));

		this.activeViewArgument = data.activeViewArgument || null;
		this.activeViewContext = data.activeViewContext || null;
		this.activeCreateArgument = data.activeCreateArgument || null;
		this.activeCreateContext = data.activeCreateContext || null;
		this.activeEditArgument = data.activeEditArgument || null;
		this.activeEditContext = data.activeEditContext || null;
		this.activeSaveArgument = data.activeSaveArgument || null;
		this.activeSaveContext = data.activeSaveContext || null;
		this.activeCancelArgument = data.activeCancelArgument || null;
		this.activeCancelContext = data.activeCancelContext || null;
		this.activeUrl = data.activeUrl || null;
		this.activeOperations = data.activeOperations || null;
		this.activeEnabled = true;

		data.commands && $.each(data.commands, (i, c) => {
			this.commands[i] = new ObjectCommandButton(c, this);
		});

		this.pauseUpdates(this.onInitialize);

		this.enableExpressions(true);

		if (this.canEdit) {
			//initialize deferred dependency and iscomputed expressions
			this.whenIdle(() => {
				this.enableExpressions(false);

				for (let f of this.mutableFields()) {
					if (!(f instanceof ScalarField) || !f.expression) {
						continue;
					}

					f.tryInitialiseExpression();
				}

				this.enableExpressions(true);
			});
		}

		if (this.activeUrl || this.activeOperations) {
			this.engine = new Engine(this);

			this.whenIdle(() => {
				if (this.activeOperations) {
					this.engine.runOperations(this.activeOperations);
				}

				if (this.editMode) {
					if (this.dataId() === null) {
						if (this.activeCreateContext || this.activeCreateArgument) {
							this.engine.run(this.activeCreateContext, this.activeCreateArgument, this);
						}
					}
					else {
						if (this.activeEditContext || this.activeEditArgument) {
							this.engine.run(this.activeEditContext, this.activeEditArgument, this);
						}
					}
				}
				else {
					if (this.activeViewContext || this.activeViewArgument) {
						this.engine.run(this.activeViewContext, this.activeViewArgument, this);
					}
				}
			});
		}

		this.whenIdle(() => this.isReady = true);
	}

	applyErrors(errors: { [index: string]: string; }, prefix?: string): boolean {
		if (_.isUndefined(errors)) return false;

		var hasErrors = false;

		if (prefix) {
			errors = this.pluckErrors(errors, prefix);
		}

		for (let [id, field] of Object.entries(this.fields.map)) {
			if (field instanceof ListField) {
				var listErrors = this.pluckErrors(errors, id);
				field.applyErrors(listErrors);

				if (!_.isEmpty(listErrors)) {
					hasErrors = true;
				}
			}
			else {
				if (errors[id]) {
					field.customError(errors[id]);
					errors[id] = null;
					hasErrors = true;
				};
			}
		};

		if (super.applyErrors(errors) || hasErrors) {
			this.validate(true);
		}

		return hasErrors;
	}

	applyEntityErrors(entity: Entity): void {
		this.applyErrors(entity.errors);

		var hasErrors = false;

		for (let field of this.fields.list) {
			_.each(field.items(), (item: ListItemForm, i: number) => {
				var errors = entity.values[field.id][i].errors;
				if (errors) {
					item.applyErrors(errors);
					hasErrors = true;
				}
			});
		};

		if (hasErrors) {
			this.validate();
		}
	}

	private pluckErrors(errors: { [index: string]: string; }, prefix: string): { [index: string]: string; } {
		var result: { [index: string]: string; } = {};

		_.each(errors, (v, k?: string) => {
			if (k.indexOf(prefix) == 0) {
				result[k.substr(prefix.length)] = v;
				errors[k] = null;
			}
		});

		return result;
	}

	mutableFields() {
		if (this.fields) {
			let fields: Field[] = this.editMode ? this.fields.all : this.fields.list;
			return fields.filter(x => x.editMode);
		}

		return null;
	}

	mergeValues(source: ObjectForm) {
		this.pauseUpdates(() => {
			for (let target of this.fields.scalar) {
				var f = source.findField(target.id);

				if (f) {
					target.parse(f.value());
				}
			};
		});
	}

	pauseUpdates(callback: () => void) {
		if (this.isUpdating()) return;

		ko.tasks.runEarly();
		this.activeEnabled = false;

		callback();
		ko.tasks.runEarly();

		this.activeEnabled = true;
	}

	whenIdle(callback: () => void) {
		ko.tasks.runEarly();

		if (this.isBusy()) {
			var s = this.isBusy.subscribe(value => {
				if (!value) {
					s.dispose();
					this.whenIdle(callback);
				}
			})
		}
		else {
			callback();
		}
	}

	runOperations(operations: Instruction[]) {
		this.engine = this.engine || new Engine(this);
		this.whenIdle(() => this.engine.runOperations(operations));
	}

	revert() {
		this.pauseUpdates(() => {
			this.isRemoved(false);

			for (let f of this.fields.all) {
				f.revert();
			}
		});
	}

	commit(command: Command) {
		this.errors.removeAll();
		this.clearCustomListErrors();
		this.validate();
		if (this.isValid()) {
			if (this.isPopup() && !command.command) {
				command.command = "closepopup";
			}
			if (this.activeSaveContext || this.activeSaveArgument) {
				this.engine.run(
					this.activeSaveContext,
					this.activeSaveArgument,
					this,
					() => {
						//success
						ko.tasks.runEarly();
						this.validate();
						if (this.isValid()) {
							command.proceed(this)
							if (command.isAsync) {
								this.isSaving(false);
							}
						}
						else {
							this.isSaving(false);
						}
					},
					(redirectUrl?: string) => {
						//aborted
						if (redirectUrl) {
							if (command.isAsync) {
								this.isSaving(false);
							}
							this.returnUrl = redirectUrl;
							command.shouldCancel = true;
							command.proceed(this);
						}
						else {
							this.isSaving(false);
						}
					}
				);
			}
			else {
				command.proceed(this);
				if (command.isAsync) {
					this.isSaving(false);
				}
			}
		}
		else {
			this.isSaving(false);
		}
	}

	cancel(command: Command) {
		this.suppressUnloadWarning();
		if (this.activeCancelContext || this.activeCancelArgument) {
			this.engine.run(this.activeCancelContext, this.activeCancelArgument, this,
				() => {
					command.proceed(this);
				},
				(redirectUrl?: string) => {
					if (redirectUrl) {
						this.returnUrl = redirectUrl;
						command.proceed(this);
					}
				}
			);
		}
		else {
			command.proceed(this);
		}
	}

	clearCustomListErrors() {
		for (let field of this.fields.list) {
			field.customError('');
			field.clearCustomErrors();
		};
	}

	isArchiving() {
		if (this.isArchived === true) {
			return false;
		}

		const f = this.findField('IsArchived');
		if (f && f.value() == true) {
			return true;
		}

		return false;
	}

	validate(validateArchived: boolean = false): boolean {
		let isArchiving = this.isRemoved() || this.isArchiving();
		let shouldValidate = validateArchived || !isArchiving;

		if (shouldValidate == false) {
			this.validationSummary([]);
			return true;
		}

		for (let field of this.fields.all) {
			field.validate();
		};

		return super.validate();
	}

	buildSummary(): ValidationItem[] {
		var items = super.buildSummary();

		if (this.fields) {
			for (let field of this.fields.all) {
				if (!field.isValid()) {
					items.push({
						label: field.label,
						focus: function () {
							field.focus();
						},
						message: field.error()
					});
				}
			}
		}

		return items;
	}

	qualifyId(id: string, pageUnique?: boolean) {
		return this.id ? this.id + '.' + id : id;
	}

	qualifyModelId(id: string): string | null {
		return this.shouldPost() ? this.qualifyId(id) : null;
	}

	snapshot(map, excludeTargets?: boolean) {
		map[this.idProperty] = this.dataId() || '';
		map[this.stateProperty] = (this.state && this.state()) || '';

		for (let field of this.fields.scalar) {
			field.snapshot(map);
		}
	}

	snapshotFilter(map, forPost: boolean, prefix: string = 'q', excludeEmpty: boolean = true) {
		prefix = prefix ? `${prefix}.` : '';

		for (let field of this.fields.user) {
			if (field instanceof ScalarField) {
				if (!forPost) {
					if (field instanceof ObjectSelectField) {
						if (field.multiple) {
							continue;
						}
					}
					else {
						continue;
					}
				}

				let m: _.Dictionary<any> = {}
				field.snapshot(m, true);
				_.each(m, (v, k?: string) => {
					const isEmpty = _.isNull(v) || v === '';
					if (excludeEmpty && isEmpty) return;
					map[prefix + k] = isEmpty ? null : v;
				});
			}
		};

		map[`${prefix}${ObjectForm.idProperty}`] = this.dataId();
	}

	buildQueryParams(data: any, prefix: string = 'q'): URLSearchParams {
		let r: Record<string, string> = {};

		_.each(data, (v: any, k?: string) => {
			let mustInclude = false;
			const n = k.indexOf(`${prefix}.`);
			if (n >= 0) {
				const f = this && this.findField(k.substring(n + prefix.length + 1)) as ScalarField;
				mustInclude = f && (!_.isNull(f.initialValue) || !_.isNull(f.initialValueExpression));
			}
			if ((!_.isNull(v) && (v !== '')) || mustInclude) {
				r[k] = v ?? '';
			}
		});

		return new URLSearchParams(r);
	}

	mergeQueryParams(data: any, queryId: number, queryPrefix: string = 'q'): URLSearchParams {
		const idPrefix = queryId ? `${queryId}.` : '';
		const prefix = `${idPrefix}${queryPrefix}`;

		let r: Record<string, string> = {};
		for (const [name, value] of new URLSearchParams(window.location.search)) {
			if (name.startsWith(`${prefix}.`)) {
				continue;
			}
			r[name] = value;
		}

		_.each(data, (v, k?: string) => {
			r[idPrefix + k] = v;
		});

		if (idPrefix) {
			r[`${prefix}id`] = queryId.toString();
		}

		return this.buildQueryParams(r);
	}

	findField(id: string) {
		return this.fields.map[id] || null;
	}

	lookupTarget(idOrLabel: string) {
		let result = this.fields.map[idOrLabel];

		if (result) {
			return result;
		}

		idOrLabel = idOrLabel.toLowerCase();

		for (let f of this.fields.all) {
			if (f.id.toLowerCase() === idOrLabel || f.label.toLowerCase() === idOrLabel) {
				return f;
			}
		}

		return undefined;
	}

	findList(idOrLabel): ListField {
		let lookup = this.fields.map[idOrLabel];

		if (lookup && lookup instanceof ListField) {
			return lookup;
		}

		idOrLabel = idOrLabel.toLowerCase();

		for (let field of this.fields.list) {
			if (field.id.toLowerCase() == idOrLabel || field.label.toLowerCase() == idOrLabel) {
				return field;
			}
		}

		return undefined;
	}

	lookup(context: ExpressionLookupContext, targetFieldId: string, property?: string, forComputedValue: boolean = false): any {
		if (!context || !targetFieldId) return null;

		if (targetFieldId.toLowerCase() === ObjectForm.idProperty) {
			return this.dataId();
		}

		var targetField = this.lookupTarget(targetFieldId);
		if (!targetField) return null;

		let isSelfReference = context.id == targetFieldId;
		let canPeek = isSelfReference && forComputedValue;

		if (canPeek && targetField instanceof ScalarField) {
			//refactor: This is a targeted fix for #4360 to limit the impact until we have more UI tests around dependency
			//expressions. The issue is that if a self-referencing dependency field doesn't have an active form
			//process then we want to do value() instead of peek() to track the knockout dependency so that future
			//changes will be considered correctly: http://knockoutjs.com/documentation/computed-dependency-tracking.html
			//I will inform the KIM team of the affected fields and I created a story #4427 for a more long term fix.
			if (targetField.isDependencyExpression && !targetField.activeArgument && !targetField.activeContext) {
				canPeek = false;
			}
		}

		let value = canPeek ? targetField.value.peek() : targetField.value();

		if (value === undefined && canPeek) {
			value = targetField.value();
		}

		if (property) {
			var resultForm = context.form || this;
			var object = value;

			if (object && !object.targetFormId && targetField instanceof ObjectSelectField) {
				object.targetFormId = targetField.targetId;
			}

			if (resultForm instanceof ObjectForm) {
				let id = resultForm.qualifyId(context.id, true);

				return targetField.whenIdle(() => {
					return ObjectPropertyTool.lookup(
						id,
						object,
						property,
						() => {
							context.pauseExpressions && context.pauseExpressions(true);
							context.isUpdating && context.isUpdating(true);
						},
						() => {
							context.pauseExpressions && context.pauseExpressions(false);
							ko.tasks.runEarly();
							context.isUpdating && context.isUpdating(false);
						},
						(error) => {
							console.log("lookup error:", context.id, error);

							if (context.updateError) {
								context.updateError(error);
							}
							else {
								showError(error);
							}

							context.isUpdating && context.isUpdating(false);
						}
					);
				});
			}
		}
		else {
			return value;
		}
	}

	nextSequenceValue(context: ExpressionLookupContext, sequence: string, format: boolean): any {
		if (!context || !sequence) {
			return null;
		}

		var form = context.form || this;

		if (form instanceof ObjectForm) {
			let id = form.qualifyId(context.id, true);

			return context.whenIdle(() => {
				var result: SequenceValueResult = SequenceNumberTool.Next(
					id,
					sequence,
					() => {
						context.pauseExpressions && context.pauseExpressions(true);
						context.isUpdating && context.isUpdating(true);
					},
					() => {
						context.pauseExpressions && context.pauseExpressions(false);
						ko.tasks.runEarly();
						context.isUpdating && context.isUpdating(false);
					},
					(error) => {
						console.log("sequence error:", context.id, error);

						if (context.updateError) {
							context.updateError(error);
						}
						else {
							showError(error);
						}

						context.isUpdating && context.isUpdating(false);
					}
				);

				if (!result) {
					return null;
				}

				if (format) {
					return result.formatted;
				}
				else {
					return result.raw;
				}
			});
		}
	}

	compileLookup(scopeExpression: string, targetId: string, subProperty: string, forComputedValue: boolean = false): string {
		if (targetId) {
			return `${scopeExpression}.lookup(this, '${targetId}', '${subProperty || ""}', ${forComputedValue})`;
		}
		else {
			return "null";
		}
	}

	toObjectOption(): Option {
		var id = this.dataId();
		var nameField = this.fields.map["Name"];
		var name = this.displayName || (nameField && nameField.value());

		return {
			id: id || 0,
			text: name || (id ? id + '' : ''),
			targetFormId: this.formId
		};
	}

	static isMetaField(id: string): boolean {
		return id.charAt(0) == "$";
	}

	initialiseStatusExpression(site: ExpressionSite, status: string, expression: string, defaultValue: Subscribable<any>): Subscribable<any> {
		try {
			if (!expression) return defaultValue;
			return this.compileExpression(site, expression, defaultValue);
		}
		catch (e) {
			console.log("Unable to compile ", status, " expression: ", expression, e.message);
			site.expressionError(e.message);
		}
	}

	compileExpression(site: ExpressionSite, value: string, defaultValue: Subscribable<any>, forComputedValue: boolean = false): Computed<any> {
		var pattern = /\$\{([^}]+)\}/g;
		var translated = this.parseAggregateExpression(value);

		if (translated == null) {
			translated = value.replace(pattern, (match: string, p1: string) => {
				var macro = this.parseMacro(p1);

				if (macro !== null) {
					return macro;
				}

				var parser = /([\w\s\d\.\$]+)(!|:|$)/gi;
				var matches: RegExpExecArray;

				var scope = <ObjectForm>this;
				var scopeExpression = "this.form";
				var listId: string = null;
				var targetId: string = null;

				while ((matches = parser.exec(p1)) != null) {
					var id = $.trim(matches[1]);

					switch (matches[2]) {
						case "!":
							switch (id.toLowerCase()) {
								case 'header':
								case 'document':
									//if we're inside a list item form switch the scope of the expression to the parent form
									if (this instanceof ListItemForm) {
										var itemForm = <ListItemForm>(<ObjectForm>this);
										var list = itemForm.list();
										if (list instanceof ListField) {
											scope = list.form;
											scopeExpression = "this.form.list().form";
										}
										else {
											//we are not inside a child list field, return the current value
											return "this.originalValue";
										}
									}
									else {
										//we are not inside a list item form, return the current value
										return "this.originalValue";
									}
							}
							break;

						case ":":
							listId = id;
							break;

						default:
							targetId = id;

							if (listId != null) {
								//Expression is a summary on a list field
								var listField = scope.findList(listId);
								if (listField) {
									return format("{0}.findList('{1}').getAggregateResult(this, 'sum', '{2}')", scopeExpression, listField.id, targetId);
								}
								else {
									return "null";
								}
							}

							var index = targetId.indexOf(".");
							var subProperty = '';
							if (index >= 0) {
								if (index < targetId.length) {
									subProperty = targetId.slice(index + 1);
								}
								targetId = targetId.slice(0, index);
							}

							return scope.compileLookup(scopeExpression, targetId, subProperty, forComputedValue);
					}
				}
				return null;
			});
		}

		site.expressionsEnabled = site.expressionsEnabled || ko.observable(true);
		site.expressionError = site.expressionError || ko.observable('');

		const src = `
			try {
				//exit early if expressions are currently disabled
				if (!this.expressionsEnabled()) {
					return defaultValue && defaultValue.peek();
				}

				var lookup = this.getGlobals().lookup;
				var result = ${translated};

				//executing the expression could have disabled expressions, recheck it
				if (!this.expressionsEnabled()) {
					return defaultValue && defaultValue.peek();
				}

				if (typeof(result) === 'function') {
					return result.apply(this);
				}
				else {
					return result;
				}
			}
			catch (e) {
				this.expressionError(e.message);
			}
		`;

		let compiled = Function('defaultValue', src).bind(site, defaultValue);
		return ko.computed(compiled);
	}

	private parseMacro(value: string): string {
		var pattern = /^=([^()]+)(?:\((.*)\))?$/;
		var matches = pattern.exec(value);

		if (matches == null) {
			return null;
		}

		var args = matches.length > 2 ? matches[2] : '';

		switch (matches[1].toLowerCase()) {
			case "id":
				return "this.form.dataId()";

			case "outline":
				if (this instanceof ListItemForm) {
					return "this.form.position()";
				}
				else {
					//we are not inside a list item form, return the current value
					return "this.originalValue";
				}

			case "next":
				return `this.form.nextSequenceValue(this, ${args}, false)`;

			case "formatnext":
				return `this.form.nextSequenceValue(this, ${args}, true)`;
		}

		return null;
	}

	private parseAggregateExpression(value: string): string {
		var aggregatePattern = /\s*\$(sum|count)\(([^.)]+)\.?([^.)]+)?\)\s*/i;
		var matches = aggregatePattern.exec(value);
		if (matches == null) return null;

		var fn = matches[1].toLowerCase();

		if (fn == "count" && !_.isUndefined(matches[3])) return null;

		var listId = matches[2];
		var fieldId = matches[3];
		var list = this.findList(listId);

		if (list) {
			return format("this.form.findList('{0}').getAggregateResult(this, '{1}', '{2}')", list.id, fn, fieldId);
		}
		else {
			return "this.originalValue";
		}
	}

	onInitialize() { }
}

export class ContextForm extends ObjectForm {
	onInitialize() {
		var source = getInputArgument() as ObjectForm;

		if (source) {
			this.dataId(source.dataId());

			for (let target of this.fields.scalar) {
				let f = source.findField(target.id);

				if (f) {
					target.reset(f.originalValue, f.value());
				}
				else {
					target.reset(null, null);
				}
			};
		}
	}

	save(command: Command) {
		command.command = 'popupCallback';
		command.argument = this;
		super.save(command);
	}
}

export class ListForm extends Form implements IFilterable {
	listInitialized: Observable<boolean>;

	constructor(data: any) {
		var list = new List(data);

		super(data, list, list.hasChanges, list.isBusy);

		list.form = this;

		this.listInitialized = list.initialized;

		this.canEdit = list.canEditItemsInline && list.items().length > 0;
		this.editMode = list.canAddItems || list.canEditItemsInline;

		data.commands && $.each(data.commands, (i, c) => {
			this.commands[i] = new CommandButton(c, this);
		});

		if (!list.initialized()) list.tryAutoSearch();

		this.onInitialize();
	}

	list(): List {
		return <List>this.context;
	}

	buildSummary(): ValidationItem[] {
		var items: ValidationItem[] = [];
		var list = this.list();

		if (list && !list.isValid()) {
			items.push({
				label: null,
				focus: null,
				message: list.error()
			});
		}

		return items;
	}

	validate(): boolean {
		var list = this.list();
		list.clearCustomErrors();
		list.validate();
		return super.validate();
	}

	revert() {
		this.list().revert();
	}

	exportList() {
		this.list().exportList();
	}

	onInitialize() { }

	setFilterValue(name: string, value: any) {
		const filter = this.list().filter;

		if (filter) {
			const field = filter.fields.map[name];
			if (field instanceof ScalarField) {
				field.parse(value);
			}
		}
	}

	applyFilter() {
		this.list().load();
	}
}

interface ValidationRules {
	pattern?: RegExp;
	max?: number;
	min?: number;
	precision?: number;
	requiredMessage: string;
	rangeMessage: string;
	lengthMessage: string;
	patternMessage: string;
}

export class ScalarField extends BoundObject implements Field {
	id: string;
	inputId: string;
	modelId: Subscribable<string>;

	label: string;

	value: Observable<any>;
	expression: string;
	isDependencyExpression: boolean;
	recalculateOnLoad: boolean;
	allowNull: boolean;
	shouldPost: boolean;
	expressionError = ko.observable('');

	initialValue;
	initialValueExpression: string;

	uneditable: Subscribable<boolean>;
	isValid: Subscribable<boolean>;
	error = ko.observable('');
	customError = ko.observable('');
	message = ko.observable('');

	isRequired: Subscribable<boolean>;
	rules: ValidationRules;

	isVisible: Subscribable<boolean>;
	isDisabled: Subscribable<boolean>;

	isInvalidExpression: string;
	computedInvalid: Subscribable<any>;
	isRequiredExpression: string;
	isDisabledExpression: string;
	isVisibleExpression: string;
	isComputedExpression: string;

	editMode: boolean;
	editValue: Computed<any>;
	displayValue: Computed<string>;
	persistValue: Computed<any>;
	isNull: Computed<boolean>;
	hasChanges: Subscribable<boolean>;
	linkUrl?: string;
	linkTarget?: string;
	infoUrl?: string;

	originalValue;
	computedValue: Subscribable<any>;
	pauseExpressions = ko.observable(false);
	formatString: Observable<string>;

	activeArgument: string;
	activeContext: string;

	isUpdating: Observable<boolean>;
	updateError: Observable<string>;

	onFocus: () => void;
	onBlur = () => { };

	linksDisabled: boolean;

	computedValueTargetsParent: boolean;

	expressionsEnabled: Subscribable<boolean>;

	constructor(data: any, public form: ObjectForm) {
		super();

		this.expressionsEnabled = ko.computed(() => form.enableExpressions() && !this.pauseExpressions());

		this.isUpdating = ko.observable(false);
		this.updateError = ko.observable<string>(null);

		this.activeArgument = data.activeArgument || null;
		this.activeContext = data.activeContext || null;
		this.isUpdating = ko.observable(false);

		this.id = data.id;
		this.inputId = data.inputId;
		this.modelId = koDeferredComputed(() => this.form.qualifyModelId(data.modelId || this.id));

		this.label = data.label;
		this.formatString = ko.observable(data.format || null);

		this.isVisible = ko.observable(!(data.isVisible === false || data.isHiddenInput === true));
		this.isRequired = ko.observable(data.isRequired === true || false);
		this.isDisabled = ko.observable(data.isDisabled === true || false);
		this.uneditable = this.isDisabled;

		this.isInvalidExpression = data.isInvalidExpression || null;
		this.isDisabledExpression = data.isDisabledExpression || null;
		this.isRequiredExpression = data.isRequiredExpression || null;
		this.isVisibleExpression = data.isVisibleExpression || null;
		this.expression = data.expression || null;
		this.isDependencyExpression = data.isDependencyExpression || false;
		this.recalculateOnLoad = data.recalculateOnLoad || false;
		this.isComputedExpression = data.isComputedExpression || null;
		this.allowNull = data.allowNull || false;
		this.shouldPost = data.shouldPost ?? true;
		this.linkUrl = (data.meta && data.meta.LinkUrl) || null;
		this.linkTarget = (data.meta && data.meta.LinkTarget) || null;
		this.infoUrl = (data.meta && data.meta.InfoUrl) || null;

		this.rules = {
			max: data.maximum,
			min: data.minimum,
			pattern: data.pattern && new RegExp("^" + data.pattern + "$", data.patternModifiers || '') || undefined,
			precision: data.precision,
			requiredMessage: data.requiredMessage || format(settings.strings.requiredFieldMessage, data.label),
			rangeMessage: data.rangeMessage,
			lengthMessage: data.lengthMessage,
			patternMessage: data.patternMessage
		};

		//defer to the form for edit mode unless it is explicitly false
		this.editMode = data.editMode === false ? false : form.editMode;
		this.linksDisabled = form.linksDisabled;
		this.originalValue = this.load(data.value);
		this.value = ko.observable(this.originalValue);

		this.initialValue = _.isUndefined(data.initialValue) ? null : this.load(data.initialValue);
		this.initialValueExpression = data.initialValueExpression || null;

		this.isValid = ko.observable(true);
		this.value.subscribe((currentValue) => {
			this.revalidate();
		});

		var visibilitySubscription: Subscription;
		var valueSubscription: Subscription;
		this.customError.subscribe((errorValue: string) => {
			//if a custom error is set on the field keep track of the invalid value
			//and clear the custom error if the value is changed away from that value
			if (errorValue) {
				var invalidValue = this.value();
				if (!valueSubscription) {
					valueSubscription = this.value.subscribe((currentValue) => {
						if (this.willValueChange(invalidValue, currentValue) && !this.isInvalidExpression) {
							this.customError('');
						}
					});
				}

				if (!visibilitySubscription) {
					visibilitySubscription = this.isVisible.subscribe((isVisible: boolean) => {
						if (!isVisible) {
							this.customError('');
						}
					});
				}
			}
			else {
				if (visibilitySubscription) {
					visibilitySubscription.dispose();
					visibilitySubscription = null;
				}
				if (valueSubscription) {
					valueSubscription.dispose();
					valueSubscription = null;
				}
			}
			this.revalidate();
		});

		this.editValue =
			ko.computed({
				read: this.format,
				write: this.parse,
				deferEvaluation: true
			})
			.extend({ notify: 'always' });

		this.displayValue = koDeferredComputed(() => this.display());
		this.persistValue = koDeferredComputed(() => this.save());

		this.isNull = ko.computed(() => _.isNull(this.value()));

		if (this.activeContext || this.activeArgument) {
			//only run active forms if the value has changed since the last notification, this is
			//different than if the value has changed since load, as it may change away from
			//initial and then back again, which will require another run (even though hasChanges
			//would be false at that point)
			let lastValue;
			let hasLastValue = false;

			let lock = {};

			this.value.subscribe((currentValue) => {
				//we need to add it to knockout's microtask queue or it will
				//use old values because of the call to ko.tasks.runEarly()
				ko.tasks.schedule(() =>
					this.whenIdle(
						() => {
							if (!hasLastValue) {
								lastValue = this.originalValue;
								hasLastValue = true;
							}

							if (this.willValueChange(lastValue, currentValue)) {
								lastValue = currentValue;

								if (this.form && this.form.engine && this.form.isReady) {
									this.form.engine.run(this.activeContext, this.activeArgument, this);
								}
							}
						},
						lock
					)
				);
			});
		}

		this.hasChanges = ko.computed(() =>
			this.willValueChange(this.value(), this.originalValue) && this.form.isReady
		);
	}

	willValueChange(currentValue: any, newValue: any): boolean {
		return JSON.stringify(currentValue) !== JSON.stringify(newValue);
	}

	load(x) {
		return _.isUndefined(x) ? null : x;
	}

	save() {
		var value = this.value();
		if (_.isNull(value) || _.isUndefined(value)) return '';
		else return value.toString();
	}

	revert() {
		this.value(this.originalValue);
	}

	reset(originalValue, currentValue) {
		this.originalValue = this.load(originalValue);
		this.parse(currentValue);
		this.value.valueHasMutated();
		ko.tasks.runEarly();
		this.isValid(true);
	}

	display(): string {
		var value = this.value();
		if (value == null) return settings.strings.nullText;
		else return this.format();
	}

	format() {
		var formatString = this.formatString();
		if (formatString) {
			return toString(this.value(), formatString);
		}
		else {
			return this.value();
		}
	}

	parse(data) {
		this.value(data);
	}

	private revalidate() {
		var wasValid = this.isValid();
		var isValid = this.validate();

		if (!wasValid && isValid && !this.form.isValid()) {
			this.form.validate();
		}
	}

	validate(): boolean {
		var value = this.value();
		var required = this.isRequired();

		if (this.customError()) {
			this.error(this.customError());
			this.isValid(false);
			return false;
		}

		this.error(null);

		var valid = true;
		var error = (message) => {
			this.error(message);
			valid = false;
		}

		if (_.isNull(value) || _.isUndefined(value)) {
			if (required) error(this.rules.requiredMessage);
		}
		else {
			this.validateValue(value, required, error);
		}

		this.isValid(valid);
		return valid;
	}

	validateValue(value, required: boolean, error: (string) => void) {
		//validate as a string, by default
		var r = this.rules;
		var v: string = value.toString();

		if (v.length == 0) {
			if (required) error(r.requiredMessage);
		}
		else {
			if (r.pattern && !r.pattern.test(value)) {
				error(r.patternMessage);
				return;
			}

			if ((r.min && value.length < r.min) || (r.max && value.length > r.max)) {
				error(r.lengthMessage);
				return;
			}
		}
	}

	snapshot(map, forQueryString: boolean = false) {
		if (!this.shouldPost) {
			return;
		}

		map[this.id] = this.persistValue();
	}

	tryInitialiseExpression() {
		if (this.computedValue) {
			return;
		}

		this.computedValue = ko.observable(this.originalValue);

		try {
			//if reculculateOnLoad is set, accept results immediately, otherwise, retain
			//previously computed values by ignoring results before the form is interactive
			var ignoreFirstResult = this.isDependencyExpression && !this.recalculateOnLoad;

			var calculatedExpression = this.isComputedExpression
				? `(${this.isComputedExpression}) ? (${this.expression}) : this.value()`
				: this.expression;

			this.computedValue = this.form.compileExpression(this, calculatedExpression, this.value, true);
			this.computedValue.subscribe((value) => {
				if (ignoreFirstResult) {
					this.form.whenIdle(() => {
						ignoreFirstResult = false;
					});

					return;
				}

				if (this.form.activeEnabled && this.willValueChange(this.value(), value)) {
					if (this.form.isReady) {
						this.parse(value);
					}
					else {
						this.reset(value, value);
					}
				}
			});

			this.computedValue.notifySubscribers(this.computedValue());
		}
		catch (e) {
			this.expressionError(e.message);
			this.value = ko.observable('###');
		}
	}

	compileInitialValueExpression(): Computed<any> {
		if (!this.initialValueExpression) return null;

		try {
			return this.form.compileExpression(this, this.initialValueExpression, null);
		}
		catch (e) {
			this.expressionError(e.message);
		}

		return null;
	}

	getGlobals() : ExpressionGlobals {
		return new ExpressionGlobals(this);
	}

	dismissMessage() {
		this.message(null);
	}

	focus() {
		this.onFocus && this.onFocus();
	}

	initialiseStatusExpressions() {
		if (this.editMode) {
			this.computedInvalid = this.form.initialiseStatusExpression(this, "isInvalid", this.isInvalidExpression, null);
			if (this.computedInvalid) {
				this.computedInvalid.subscribe((value) => {
					if (value) {
						if (_.isString(value)) {
							this.customError(value);
						}
						else {
							this.customError(settings.strings.invalidField);
						}
					}
					else {
						this.customError('');
					}
				});
				this.computedInvalid.notifySubscribers(this.computedInvalid());
			}

			var disabledExpression = (this.isDisabledExpression && this.isComputedExpression)
				? `(${this.isDisabledExpression}) || (${this.isComputedExpression})`
				: this.isDisabledExpression || this.isComputedExpression || null;

			if (disabledExpression && !this.isDisabled()) {
				this.isDisabled = this.form.initialiseStatusExpression(this, "isDisabled", disabledExpression, this.isDisabled);
				this.uneditable = this.isDisabled;
			}
		}

		if (!this.isRequired()) {
			this.isRequired = this.form.initialiseStatusExpression(this, "isRequired", this.isRequiredExpression, this.isRequired);

			this.isRequired.subscribe(value => {
				if (!value) {
					this.revalidate();
				}
			});
		}

		if (this.isVisible()) {
			this.isVisible = this.form.initialiseStatusExpression(this, "isVisible", this.isVisibleExpression, this.isVisible);
		}
	}

	isCalculated(): boolean {
		return this.uneditable() && this.expression && !this.isDependencyExpression;
	}

	whenIdle(callback: () => any, exclusiveLock?: any) {
		return ExpressionLookup.whenIdle(this.isUpdating, callback, exclusiveLock);
	}
}

export class StringField extends ScalarField {
	trim: boolean;

	constructor(data: any, form: ObjectForm) {
		super(data, form);
		this.trim = data.trim || false;
	}

	load(x) {
		if (_.isNull(x) || _.isUndefined(x)) {
			x = '';
		}

		if (_.isObject(x as any) && !_.isUndefined(x.id)) {
			x = x.text || x.id;
		}

		if (this.trim) {
			x = $.trim(x);
		}

		return x + '';
	}

	parse(data) {
		this.value(
			this.load(data)
		);
	}
}

export class NumberField extends ScalarField {
	willValueChange(currentValue: any, newValue: any): boolean {
		const p = this.rules.precision;
		if (!_.isUndefined(p) && !_.isNull(p)) {
			if (NumberField.isValidNumber(currentValue) && NumberField.isValidNumber(newValue)) {
				var x = Number(currentValue);
				var y = Number(newValue);
				return x.toFixed(p) != y.toFixed(p);
			}
		}

		return super.willValueChange(currentValue, newValue);
	}

	format() {
		return this.formatValue(this.value());
	}

	formatValue(value) {
		var formatString = this.formatString();
		if (formatString) {
			var result = toString(value, formatString);

			if(!this.editMode && result === '0' && isEmptyWhenZeroFormat(formatString)) {
				result = null;
			}

			return result;
		}
		else {
			var p = this.rules.precision;
			if (NumberField.isValidNumber(p) && NumberField.isValidNumber(value)) {
				return toString(value, "n" + p);
			}
			else {
				return value;
			}
		}
	}

	parse(data) {
		if (_.isNull(data) || _.isUndefined(data) || _.isNaN(data) || data === '') {
			this.value(null);
			return;
		}

		if (_.isObject(data as any) && !_.isUndefined(data.id)) {
			data = data.id;
		}

		if (_.isNumber(data)) {
			this.value(this.round(data));
		}
		else {
			var parsed = kendo.parseFloat(data);

			if (NumberField.isValidNumber(parsed)) {
				var format = this.formatString();

				if (format && format.match(/^p[0-9]*$/)) {
					parsed = parsed / 100;
				}

				this.value(this.round(parsed));
			}
			else {
				//update observers using the pre edit value
				this.value.valueHasMutated();
			}
		}
	}

	round(value: number): number {
		var p = this.rules.precision;
		if (NumberField.isValidNumber(p) && NumberField.isValidNumber(value)) {
			return parseFloat(value.toFixed(p));
		}
		else {
			return value;
		}
	}

	validateValue(value, required: boolean, error: (string) => void) {
		var r = this.rules;
		var v: number = value;

		if (NumberField.isValidNumber(v)) {
			if (required && !this.allowNull && parseFloat(value) == 0) {
				error(settings.strings.valueCannotBeZero);
				return;
			}
			else if ((r.min != null && v < r.min) || (r.max != null && v > r.max)) {
				error(r.rangeMessage);
				return;
			}
		}
		else {
			if (required) error(r.requiredMessage);
		}
	}

	save() {
		var x = this.value();
		return NumberField.isValidNumber(x) ? x.toString() : '';
	}

	static isValidNumber(x) {
		if (_.isNull(x)) return false;
		if (_.isUndefined(x)) return false;
		if (!_.isNumber(x)) return false;
		if (_.isNaN(x)) return false;
		if (!_.isFinite(x)) return false;
		return true;
	}
}

export class DateField extends ScalarField {
	constructor(data: any, form: ObjectForm) {
		super(data, form);
	}

	load(x) {
		return this.parseDate(x, 's');
	}

	parseDate(data: string, format?: string) {
		if (_.isString(data)) {
			var s: String = data;
			if (s.toLowerCase().indexOf('/date(') == 0) {
				return new Date(parseInt(s.substr(6)));
			}
		}

		return kendo.parseDate(data, format || this.formatString());
	}

	parse(data) {
		if (_.isDate(data)) {
			//data could be from a different window so construct a new data to avoid any permission issues
			this.value(new Date(data.valueOf()));
		}
		else {
			var parsed = this.parseDate(data, 's') || this.parseDate(data);
			if (data && !parsed) {
				//force the view to clear - valueHasMutated is not updating the view to its previous value
				this.value(data);
				this.value(null);
			} else {
				this.value(parsed);
			}
		}
	}

	save() {
		return toString(this.value(), 's');
	}
}

class BoolField extends ScalarField {
	constructor(data: any, form: ObjectForm) {
		super(data, form);
	}

	display() {
		var value = this.value();

		if (_.isBoolean(value)) {
			return value ? "Yes" : "No";
		}
		else {
			return "Default";
		}
	}

	format() {
		var x = this.value();
		return x === null ? '' : x.toString();
	}

	load(x) {
		if (x) {
			return true;
		}
		else {
			return false;
		}
	}

	parse(data) {
		if (_.isBoolean(data)) {
			this.value(data);
		}
		else if (_.isNumber(data)) {
			this.value(data === 0 ? false : true);
		}
		else if (_.isString(data)) {
			switch ((<string>data).toLowerCase()) {
				case 'true':
				case '1':
					this.value(true);
					break;
				case 'false':
				case '0':
					this.value(false);
					break;
				case '':
					this.value(null);
					break;
			}
		}
		else if (_.isNull(data) || _.isUndefined(data)) {
			this.value(false);
		}
		else {
			//notify subscribers with previous value
			this.value.valueHasMutated();
		}
	}

	validateValue(value, required: boolean, error: (string) => void) {
		if (!_.isBoolean(value) && required) error(this.rules.requiredMessage);
	}
}

interface File {
	name: string;
	contentType: string;
	uniqueId: string;
}

export class FileField extends ScalarField {
	imageUrl?: string;

	constructor(data: any, form: ObjectForm) {
		super(data, form);

		this.imageUrl = (data.meta && data.meta.ImageUrl) || null;
	}

	load(x) {
		if (x && x.name) {
			return x;
		}
		else if (x && _.isString(x)) {
			var fileString = x + '';

			var parts = fileString.split(':');
			if (parts.length > 2) {
				var file: File = {
					name: parts[0],
					contentType: parts[1],
					uniqueId: parts.slice(2).join(':')
				}
				return file;
			}
			else {
				var file: File = {
					name: fileString,
					contentType: '',
					uniqueId: '',
				}
				return file;
			}
		}
		return null;
	}

	display(): string {
		var value: File = this.value();
		if (value == null) return super.display();

		return value.name;
	}

	parse(data) {
		this.value(this.load(data));
	}

	save() {
		var value: File = this.value();
		if (value) {
			return value.name + ':' + value.contentType + ':' + value.uniqueId;
		}
		else {
			return '';
		}
	}

	clear() {
		this.value(null);
	}

	format() {
		var value = this.value();
		return value && value.uniqueId;
	}
}

export interface Option {
	id: any;
	text: string;
	children?: Option[];
	target?: any;
	targetFormId?: number;
	targetName?: string;
	values?: { [key: string]: any };
	meta?: { [key: string]: any };
	isAbstract?: boolean;
}

export function makeAbstractOption(value: string) : Option {
	return {
		id: 0,
		text: value,
		targetName: value,
		isAbstract: true,
		values: {
			IsAbstract: true
		}
	};
}

export interface SelectFormatter {
	formatSelection?: (object: any) => string;
	formatResult?: (object: any) => string;
}

export class SelectField extends ScalarField {
	options: ObservableArray<any>;
	optionsMap: ObservableArray<any>;
	selectedOptionIds: ObservableArray<any>;
	selectFormatter: SelectFormatter;
	selectedItem: Observable<any>;
	statusColorClass: Computed<string>;

	enableLink: Computed<boolean>;

	listFilterMap: { [index: string]: Computed<any>; };

	createUrl: string;
	selectUrl: string;

	getMapId(id: any) {
		return (id === null ? '' : id + '').toLowerCase();
	}

	private mapOptions(map: Object, options: Option[]) {
		if (!options) {
			return;
		}

		for (const item of options) {
			map[this.getMapId(item.id)] = item;
			if (item.children) {
				this.mapOptions(map, item.children);
			}
		}
	}

	constructor(data: any, form: ObjectForm, public multiple: boolean, public objectValues: boolean = false) {
		super(data, form);

		this.multiple = multiple;

		var options = data && data.meta && parseJson<any[]>(data.meta.Options);
		if (!options && !objectValues) {
			options = this.items().map(x => this.buildOption(x));
		}
		this.options = ko.observableArray(options || []);

		this.optionsMap = <ObservableArray<any>><any>ko.computed(() => {
			var map = {};
			this.mapOptions(map, this.options());
			return map;
		});

		if (this.multiple) {
			this.selectedItem = ko.observable(null);
		}
		else {
			this.selectedItem = this.value;
		}

		this.objectValues = this.objectValues || false;

		this.selectedOptionIds = <ObservableArray<any>><any>ko.computed({
			read: () => {
				var x = _.map(this.selectedItems(), ObjectForm.idProperty);
				return x;
			},
			write: (newValue) => {
				var map = this.optionsMap();
				this.value(
					_.map(newValue, (id) => map[this.getMapId(id)])
				);
			}
		});

		this.isNull = ko.computed(() => this.items().length == 0);

		this.statusColorClass = ko.computed(() => {
			if (!this.multiple) {
				var items = this.selectedItems();
				if (items.length == 1 && items[0].meta && items[0].meta.color) {
					return `background-${items[0].meta.color.toLowerCase()}`;
				}
			}

			return null;
		});

		this.createUrl = (data.meta && data.meta.CreateUrl) || null;
		this.selectUrl = (data.meta && data.meta.SelectUrl) || null;

		this.enableLink = ko.computed(() => {
			if (this.linksDisabled || this.isNull()) {
				return false;
			}

			var value = this.selectedItem();
			if (value && value.isAbstract) {
				return false;
			}

			return true;
		});

		this.listFilterMap = null;
		if (data.listFilterMap) {
			var map = {};

			_.each(data.listFilterMap, (value, key) => {
				if (value) {
					map[key] = this.form.compileExpression(this, value.toString(), ko.observable());
				}
			});

			this.listFilterMap = map;
		}
	}

	create() {
		if (!this.createUrl) return;

		showModalPage({
			url: this.createUrl,
			callback: (value, context) => {
				this.focus();

				if (value) {
					this.parse(value);
				}
			}
		});
	}

	info(): void {
		if (!this.infoUrl) return;

		showModalPage({
			url: this.buildInfoUrl(),
			callback: () => this.focus()
		});
	}

	buildInfoUrl(): string {
		if (!this.infoUrl) return null;

		let map = {};
		this.snapshot(map, false, 'entity');

		return this.buildUrl(this.infoUrl, map);
	}

	select() {
		if (!this.selectUrl) return;

		let map = {};
		this.buildFilterSnapshot(map, false);

		showModalPage({
			url: this.buildUrl(this.selectUrl, map),
			callback: (value, context) => {
				this.focus();

				if (value) {
					//set the selection from the popup page
					this.parse(value);
				}
				else {
					//return the current selection to the popup page
					return this.value();
				}
			}
		});
	}

	buildFilterSnapshot(map, forPost: boolean) {
		if (this.listFilterMap) {
			_.each(this.listFilterMap, (value: Computed<any>, key) => {
				var v = value();
				if (_.isObject(v)) {
					_.each(v, (propValue, prop) => {
						if (_.isNull(propValue) || v === '' || _.isObject(propValue)) {
							return;
						}

						map[`q.${key}.${prop}`] = propValue;
					});
				}
				else {
					map[`q.${key}`] = v;
				}
			});
		}
		else {
			this.form.snapshotFilter(map, forPost);
		}
	}

	display(): string {
		var items = _.map(this.selectedItems(), 'text');
		if (items.length > 0) return items.join(', ');
		else return settings.strings.nullText;
	}

	saveItems(id: string): any[] {
		var result = [];
		var makeId = () => {
			if (this.multiple) {
				return id + '[' + result.length + ']';
			}
			else {
				return id;
			}
		};

		var i = this.items();
		if (i.length) {
			_.forEach(i, (x) => {
				result.push({
					id: makeId(),
					value: x + ''
				});
			});
		}
		else if (!this.multiple) {
			result.push({
				id: id,
				value: ''
			});
		}

		return result;
	}

	save() {
		return this.saveItems(this.modelId());
	}

	selectedItems(): Option[] {
		var x = this.value();
		if (x == null) return [];
		var map = this.optionsMap();

		if (this.multiple && _.isArray(x)) {
			var result: Option[] = [];
			_.forEach(x, (item) => {
				var option = map[this.getMapId(item)];
				option && result.push(option);
			});
			return result;
		}
		else {
			var option = map[this.getMapId(x)];
			if (option) return [option];
			return [];
		}
	}

	items(): any[] {
		var x = this.value();
		if (x == null) return [];

		if (this.multiple && _.isArray(x)) {
			return x;
		}
		else {
			return [x];
		}
	}

	format() {
		var items = _.map(this.selectedItems(), ObjectForm.idProperty);
		if (items.length > 0) return items.join(',');
		else return '';
	}

	getOptions(data): Option[] {
		if (data == null) return [];

		var map = this.optionsMap();

		if (this.multiple) {
			if (!_.isArray(data)) {
				data = (String(data)).split(',');
			}

			var result = [];

			_.forEach(data, (item) => {
				var id = this.getMapId(item);
				var option = map[id] || this.buildOption(item);

				if (option) {
					result.push(option);
				}
			});

			return result;
		}
		else {
			var id = this.getMapId(data);
			var option = map[id] || this.buildOption(_.isNull(data) ? '' : `${data}`);

			if (option) {
				return [option];
			}
			else {
				return [];
			}
		}
	}

	parse(data) {
		if (data != null) {
			if (this.multiple && _.isString(data)) {
				data = (String(data)).split(',');
			}
			else if (!this.optionsMap()[data]) {
				let text = String(data).toLowerCase();
				let option = this.options().find(x => x.text.toLowerCase() === text);
				if (option != null) {
					data = option.id;
				}
			}
		}

		this.setValue(data);
	}

	revert() {
		this.parse(this.originalValue);
	}

	setValue(data) {
		if (data != null) {
			if (this.multiple) {
				if (!_.isArray(data)) {
					data = [data];
				}

				if (!data.length) {
					data = null;
				}
			}
			else if (_.isArray(data)) {
				if (data.length) {
					data = data[0];
				}
				else {
					data = null;
				}
			}
		}

		this.value(data);
	}

	buildOption(id): Option {
		return {
			id: id,
			text: id
		};
	}

	snapshot(map, forQueryString: boolean = false, key?: string) {
		if (!this.shouldPost) {
			return;
		}

		if (!key) {
			key = this.id;
		}

		if (forQueryString) {
			map[key] = this.format();
		}
		else {
			_.each(this.saveItems(key), (item) => {
				map[item.id] = item.value;
			});
		}
	}

	private buildUrl(path: string, data: { [id: string]: any; }): string {
		let keys = Object.keys(data);
		if (keys.length) {
			path = path + (path.indexOf("?") < 0 ? "?" : "&") +
				keys.map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&');
		}

		return path;
	}
}

export class ObjectSelectField extends SelectField {
	targetId: number;
	dataUrl: string;
	allowAbstract: boolean;
	pendingIds: number[];

	constructor(data: any, form: ObjectForm, multiple: boolean) {
		super(data, form, multiple, true);

		this.targetId = (data.meta && data.meta.TargetId) || null;
		this.dataUrl = (data.meta && data.meta.DataSourceUrl) || null;
		this.allowAbstract = (data.meta && data.meta.AllowAbstract) || false;
		this.pendingIds = [];
	}

	willValueChange(currentValue: any, newValue: any): boolean {
		if (this.isObject(currentValue) && this.isObject(newValue)) {
			let x = currentValue as Option;
			let y = newValue as Option;

			var equal =
				x.id === y.id &&
				x.text === y.text &&
				x.targetFormId === y.targetFormId &&
				x.isAbstract === y.isAbstract;

			if (!equal) {
				return true;
			}

			if (y.target && !x.target) {
				return true;
			}

			if (y.values && !x.values) {
				return true;
			}

			return false;
		}
		else {
			return super.willValueChange(currentValue, newValue);
		}
	}

	saveItems(id: string): any[] {
		var result = [];

		var makeId = (key: string, index: number) => {
			if (this.multiple) {
				return id + '[' + index + '].' + key;
			}
			else {
				return id + '.' + key;
			}
		};

		var i = this.items();
		if (i.length) {
			_.forEach(i, (x, n?: number) => {
				_.forEach(x, (value, key?: string) => {
					if (!_.isObject(value)) {
						result.push({
							id: makeId(key, n),
							value: value
						});
					}
				});
			});
		}
		else {
			result.push({
				id: id,
				value: ''
			});
		}

		return result;
	}

	selectedItems(): Option[] {
		if (!this.options.length) {
			return this.items();
		}

		return super.selectedItems();
	}

	items(): any[] {
		var x = this.value();
		if (x == null) return [];

		if (this.multiple && _.isArray(x)) {
			return x
		}
		else if (_.isObject(x)) {
			return [x];
		}
		else {
			return [];
		}
	}

	load(x) {
		if (_.isNull(x) || _.isUndefined(x)) {
			return null;
		}
		if (_.isArray(x)) {
			return _.filter(x, (e) => !_.isNull(e));
		}
		return x;
	}

	parse(data) {
		if (_.isNull(data) || _.isUndefined(data)) {
			this.value(null);
		}
		else if (this.isObject(data)) {
			this.setValue(this.convertToOption(data));
		}
		else {
			var options = this.getOptions(data);
			this.setValue(options);
		}
	}

	isObject(data): boolean {
		return _.isObject(data) && !_.isArray(data)
	}

	getOptions(data): Option[] {
		var options = super.getOptions(data);

		//we remove id's that are 0 for non abstract records because we treat 0 as null
		options = options.filter(o => o.id + '' !== '0' || o.isAbstract);

		if (this.dataUrl && this.pendingIds.length) {
			this.isUpdating(true);

			var q = {
				i: this.form.dataId() || 0,
				ids: this.pendingIds,
				n: this.pendingIds.length
			};

			var map = {};
			_.each(options, x => map[this.getMapId(x.id)] = x);

			jQuery.post(
				appUrl(this.dataUrl),
				q
			)
				.done((result, _, __) => {
					var resultMap = {};

					var mapOptions = (values: Option[]) => {
						values.forEach(x => {
							if (x.id !== undefined) {
								resultMap[this.getMapId(x.id)] = x;
							}

							if (x.children) {
								mapOptions(x.children);
							}
						});
					}

					result && mapOptions(result);

					this.pendingIds.forEach(i => {
						var id = this.getMapId(i);
						var option = map[id];

						if (!option) {
							return;
						}

						var resolved = resultMap[id];
						if (resolved) {
							option.text = resolved.text;
							option.values = resolved.values;
						}
						else {
							if (this.multiple) {
								this.value(options.filter(x => x.id !== id));
							}
							else {
								if (this.allowAbstract) {
									this.value(makeAbstractOption(id));
								}
								else {
									this.value(null);
								}
							}
						}
					});

					this.value.valueHasMutated();
				})
				.fail((xhr, _, __) => {
					this.updateError(xhr.responseText);
				})
				.always(() => {
					this.pendingIds = [];
					this.isUpdating(false);
				});
		}

		return options;
	}

	buildOption(id): Option {
		if (!id) return null;

		if (_.isObject(id)) {
			return this.convertToOption(id);
		}

		var option: Option = {
			id: id,
			text: this.dataUrl ? settings.strings.loadingMessage : id,
			targetFormId: this.targetId
		};

		if (this.dataUrl) {
			this.pendingIds.push(id);
		}

		return option;
	}

	private convertToOption(data): Option {
		if (_.isFunction(data.dataId)) {
			//can't check for instance of object form as it could have been passed back
			//from a child window
			var form = <ObjectForm>data;
			data = form.toObjectOption();
			data.targetFormId = data.targetFormId || this.targetId;
		}
		else if (_.isUndefined(data.targetFormId)) {
			data.targetFormId = this.targetId;
		}

		data.text = data.text || data.displayName || data.id;

		return data;
	}
}

export class ListItemForm extends ObjectForm {
	index: Observable<number>;
	url: string;
	editUrl: string;
	cloneUrl: string;
	canRemove: boolean;
	canMoveUp: Computed<boolean>;
	canMoveDown: Computed<boolean>;
	isSelected: Computed<boolean>;
	showEditButton: boolean;
	showViewButton: boolean;
	showContextEditButton: boolean;

	constructor(data, index: number, list: List, useInitialValues?: boolean) {
		var isLocked: boolean = data.isLocked || false;
		data.canView = data.canView !== false;

		//new items are always editable (in edit mode) but existing items obey the rules
		data.canEdit = !isLocked && (data.canEdit !== false);
		data.editMode = data.canEdit && (_.isNumber(data.dataId) ? list.canEdit : list.canAdd);

		var context = {
			list: list,
			index: index,
			useInitialValues: useInitialValues || false
		};

		super(data, context);

		this.shouldPost(list.shouldPost());
		list.shouldPost.subscribe(value => this.shouldPost(value));

		this.index = ko.observable(index);
		var s = this.index.subscribe(() => {
			//ensure expressions are calculated when the index changes
			this.hasChanges.notifySubscribers(this.hasChanges());
			s.dispose();
		});

		this.editUrl = null;
		this.url = null;
		this.cloneUrl = null;

		function parseTokens(url: string): string {
			return url
				.replace("{id}", data.dataId)
				.replace("{formId}", data.formId || list.prototype.formId)
		}

		if (_.isNumber(data.dataId)) {
			if (data.canEdit && list.editUrl) {
				this.editUrl = parseTokens(list.editUrl);
			}

			if (data.canView && list.viewUrl) {
				this.url = parseTokens(list.viewUrl);
			}

			if (data.canView && list.cloneUrl) {
				this.cloneUrl = parseTokens(list.cloneUrl);
			}
		}

		this.showEditButton = this.editUrl && this.canEdit && list.canEditItems;
		this.showViewButton = this.url && list.canViewItems;
		this.showContextEditButton = (this.editMode && list.contextFormUrl) ? true : false;

		this.canRemove = !isLocked && (data.canRemove !== false) && (list.canRemove || !_.isNumber(data.dataId));

		this.isSelected = ko.computed(() => list.selections.contains(this));

		this.canMoveUp = ko.computed(() => {
			var index = this.index();
			var items = this.list().items();
			return this.editMode && (index > 0) && (index < items.length) && items[index - 1].editMode;
		});

		this.canMoveDown = ko.computed(() => {
			var index = this.index();
			var items = this.list().items();
			return this.editMode && (index < items.length - 1) && items[index + 1].editMode;
		});

		if (this.editMode && list.canOrderItems && list.orderField) {
			this.index.subscribe((value) => {
				var f = this.fields.map[this.list().orderField];
				f && f.value(value + 1);
			});
			this.index.notifySubscribers(this.index());
		}

		if (list.selectMode) {
			for (let f of this.mutableFields()) {
				if (f instanceof ScalarField && f.isCalculated()) {
					continue;
				}

				f.hasChanges.subscribe(newValue => {
					if (newValue && !this.isSelected()) {
						this.list().selectItem(this, true);
					}
				});
			}

			this.isSelected.subscribe(() => this.validate());

			const state = this.state;
			this.state =
				ko.computed(() => {
					if (this.isSelected()) {
						return state();
					}
					else {
						return FormState.Unmodified;
					}
				})
				.extend({ notify: 'always' });

			this.validate();
		}
	}

	public list(): List {
		return <List>(this.context.list);
	}

	onInitialize() {
		if (!this.context.useInitialValues) {
			return;
		}

		for (let field of this.fields.scalar) {
			if (field.id == this.list().relationship) {
				continue;
			}

			const useInitialValue = (_.isNull(field.originalValue) || field.originalValue === '') && !_.isNull(field.initialValue);

			if (useInitialValue) {
				field.reset(field.initialValue, field.initialValue);
			}
			else if (field.initialValueExpression) {
				var c = field.compileInitialValueExpression();

				if (c != null) {
					var s = c.subscribe((value) => {
						if (field.expressionsEnabled() && !field.isUpdating()) {
							if (!field.expressionError()) {
								field.reset(value, value);
							}

							ko.tasks.runEarly();
							s.dispose();
							c.dispose();
						}
					});

					let errorHandler = field.expressionError.subscribe(error => {
						field.customError(`${settings.strings.expressionError}: ${error}`);
					});

					c.notifySubscribers(c());

					if (this.enableExpressions()) {
						this.whenIdle(() => {
							errorHandler.dispose();
						});
					}
					else {
						let onExecuted = this.enableExpressions.subscribe(enabled => {
							if (enabled) {
								this.whenIdle(() => {
									errorHandler.dispose();
									onExecuted.dispose();
								});
							}
						});
					}
				}
			}
		};
	}

	select(command?: Command) {
		var list = this.list();
		list.selectItem(this, true);
		list.select(command);
	}

	qualifyId(id: string, pageUnique?: boolean) {
		var list = this.list();

		var qualifyId = list.qualifier + '[' + this.getIndex() + '].' + id;
		if (pageUnique) {
			return list.queryId + '.' + qualifyId;
		}
		else {
			return qualifyId;
		}
	}

	moveUp() {
		if (!this.canMoveUp()) return;

		var list = this.list();
		var index = this.index();
		var item = list.items.remove(this)[0];
		list.items.splice(index - 1, 0, item);
	}

	moveDown() {
		if (!this.canMoveDown()) return;

		var list = this.list();
		var index = this.index();
		var item = list.items.remove(this)[0];
		list.items.splice(index + 1, 0, item);
	}

	remove() {
		this.revert();
		this.list().remove(this);
	}

	restore() {
		this.isRemoved(false);
	}

	snapshot(map, excludeTargets?: boolean) {
		super.snapshot(map, excludeTargets);

		var list = this.list();
		if (list.relationship && list.relationshipTarget) {
			//synthesize the parent relationship
			map[list.relationship + '.Id'] = list.relationshipTarget.dataId();

			if (!(excludeTargets === true)) {
				var targetMap: _.Dictionary<any> = {};
				list.relationshipTarget.snapshot(targetMap);
				_.each(targetMap, (value, key?: string) => {
					map[list.relationship + '.Target.' + key] = value;
				});
			}
		}
	}

	snapshotFilter(map, forPost: boolean) {
		super.snapshotFilter(map, forPost);

		var list = this.list();
		if (list.relationship && list.relationshipTarget) {
			//synthesize the parent relationship
			map['q.' + list.relationship + '.Id'] = list.relationshipTarget.dataId();

			if (forPost) {
				var targetMap: _.Dictionary<any> = {};
				list.relationshipTarget.snapshot(targetMap);
				_.each(targetMap, (value, key?: string) => {
					map['q.' + list.relationship + '.Target.' + key] = value;
				});
			}
		}
	}

	getIndex() {
		//index may be needed before the index observable is available
		return this.index ? this.index() : this.context.index;
	}

	position(): number {
		return this.getIndex() + this.list().pager.start();
	}

	lookup(resultField: Field, targetFieldId: string, property?: string, forComputedValue: boolean = false): any {
		if (!resultField) return null;

		var list = this.list();
		if (targetFieldId == list.relationship) {
			var targetForm = list.relationshipTarget;

			if (!targetForm) return null;

			if (property) {
				//lookup the property on the relationship form
				var parts = property.split('.');
				var fieldId = parts.shift();
				var subProperty = parts.join('.');

				resultField.computedValueTargetsParent = forComputedValue;

				return targetForm.lookup(resultField, fieldId, subProperty, forComputedValue);
			}
			else {
				//synthesise an object that represents the relationship form
				var option = targetForm.toObjectOption();
				option.target = {};

				for (let [id, field] of Object.entries(this.fields.map)) {
					option.target[id] = field.value();
				}

				return option;
			}
		}
		else {
			return super.lookup(resultField, targetFieldId, property, forComputedValue);
		}
	}

	contextEdit() {
		if (!this.showContextEditButton) return;

		this.list().showContextForm(this, (contextForm) => {
			this.mergeValues(contextForm);
		});
	}

	validate(): boolean {
		let isUnselected = this.list().selectMode && this.isSelected && !this.isSelected();

		if (this.errors().length == 0 && (!this.editMode || isUnselected)) {
			//read only and unselected rows are always valid unless there are row level errors
			for (let f of this.fields.all) {
				f.isValid(true);
			}

			this.validationSummary([]);
			return true;
		}
		else {
			return super.validate();
		}
	}

	revert() {
		super.revert();

		if (this.list().selectMode) {
			this.validate();
		}
	}
}

export class FilterForm extends ObjectForm {
	list: List;
	hasVisibleFilter: Computed<boolean>;
	hasUserFilter: Observable<boolean>;

	constructor(data, list: List) {
		data.editMode = true;
		super(data);

		this.list = list;

		this.hasVisibleFilter = ko.computed(() => {
			var hasVisibleFilter = false;

			for (let field of this.fields.scalar) {
				hasVisibleFilter = hasVisibleFilter || (field.isVisible() && !field.isNull());
			}

			return hasVisibleFilter;
		});

		this.hasUserFilter = ko.observable(data.hasUserFilter ?? false);
	}

	qualifyId(id: string) {
		return 'q.' + id;
	}

	snapshot(map) {
		var filterMap: _.Dictionary<any> = {};

		for (let f of this.fields.scalar) {
			f.snapshot(filterMap, true);
		}

		_.each(filterMap, (value, key?: string, object?: Object) => {
			map[this.qualifyId(key)] = value;
		});
	}

	clear() {
		if (this.list.assertNoChanges()) {
			for (let field of this.fields.scalar) {
				if (field.isVisible()) {
					field.value(null);
				}
			}

			this.list.initialized(false);
		}
	}

	search() {
		ko.tasks.runEarly();

		if (this.list.assertNoChanges() && this.validate()) {
			this.list.pager.offset(0);
			this.list.load();
		}
	}

	saveUserFilter() {
		let filter = {};
		this.list.filter.snapshotFilter(filter, true, '', false);
		saveUserFilter(UserFilterType.List, this.list.queryId, filter, () => this.hasUserFilter(true));
	}

	clearUserFilter() {
		clearUserFilter(UserFilterType.List, this.list.queryId, () => this.hasUserFilter(false));
	}
}

interface ListPrototypeData {
	dataId;
	formId?;
	key?;
	parentKey?;
	idProperty;
	displayName: string;
	cloneUrl?;
	activeUrl;
	activeCreateArgument?;
	isLocked;
	isArchived;
	canEdit?;
	canView?;
	canRemove?;
	linksDisabled?;
	fields: { [id: string]: any; };
	errors?: { [id: string]: string; };
	activeOperations?: Instruction[];
}

interface ListItemData {
	id: string;
	formId?: number;
	key?: string;
	parentKey?: string;
	displayName: string;
	cloneUrl?: string;
	isLocked: boolean;
	isArchived: boolean;
	canEdit?: boolean;
	canView?: boolean;
	canRemove?: boolean;
	values: { [id: string]: any; };
	errors?: { [id: string]: string; };
	activeOperations?: Instruction[];
}

interface ListResult {
	error?: string;
	total: number;
	items: ListItemData[];
	aggregates?: { [id: string]: AggregateResults; };
}

interface ListRequest {
	url: string;
	data?: any;
}

interface AggregateResults {
	sum: number;
}

class ListSelections {
	items = ko.observableArray<Option>();
	private keys: { [id: string]: boolean; } = {};
	private list: List;
	count: Computed<number>;
	total: Subscribable<number>;

	constructor(list: List) {
		this.list = list;
		this.count = ko.computed(() => this.items().length);

		if (list.selectTotalField) {
			this.total = ko.computed(() => {
				var result = 0;

				_.each(this.items(), item => {
					if (!item.values) return;

					var value = item.values[list.selectTotalField];
					value = ko.unwrap(value);

					if (NumberField.isValidNumber(value)) result += value;
				});

				return result;
			});
		}
		else {
			this.total = ko.observable(0);
		}
	}

	private getKey(id): string {
		return id + '';
	}

	select(item: ListItemForm) {
		var key = this.getKey(item.dataId());
		var option = this.convertToOption(item);

		if (this.list.selectMultiple) {
			if (!this.containsKey(key)) {
				this.keys[key] = true;
				this.items.push(option);
			}
		}
		else {
			this.keys = {};
			this.keys[key] = true;
			this.items([option]);
		}
	}

	selectMultiple(items: ListItemForm[]) {
		if (!this.list.selectMultiple) return;

		var options = _.map(items, item => this.convertToOption(item));
		this.selectOptions(options);
	}

	selectOptions(items: Option[]) {
		if (!this.list.selectMultiple) return;

		//filter out the items that are already selected
		items = items.filter((item) => !this.keys[this.getKey(item.id)]);

		for (var i = 0; i < items.length; i++) {
			this.keys[this.getKey(items[i].id)] = true;
		}

		this.items.valueWillMutate();
		ko.utils.arrayPushAll(this.items(), items);
		this.items.valueHasMutated();
	}

	remove(item: ListItemForm) {
		var key = this.getKey(item.dataId());
		if (this.containsKey(key)) {
			this.keys[key] = null;
			this.items.remove((selectedItem) => this.getKey(selectedItem.id) == key);
		}
	}

	removeMultiple(items: ListItemForm[]) {
		var idsToRemove: { [id: number]: boolean; } = {};

		_.each(items, (item) => {
			var id = item.dataId();
			var key = this.getKey(id);
			if (this.containsKey(key)) {
				this.keys[key] = null;
				idsToRemove[id] = true;
			}
		});

		this.items.remove((item) => idsToRemove[item.id]);
	}

	clear() {
		this.keys = {};
		this.items.removeAll();
		this.list.resetRangeSelection();
	}

	contains(item: ListItemForm) {
		this.items();	//update subscribers if items changes
		return this.containsKey(this.getKey(item.dataId()));
	}

	refresh() {
		ko.tasks.runEarly();
		if (this.count() == 0) return;

		var selections = _.filter(this.list.items(), (item: ListItemForm) => item.isSelected());

		this.removeMultiple(selections);
		this.selectMultiple(selections);
		this.list.resetRangeSelection();
	}

	formatTotal(): string {
		var totalField = this.list.selectTotalField;
		if (!totalField) return '';

		var f = <NumberField>this.list.findPrototypeField(totalField);
		if (!f) return '';

		return f.formatValue(this.total());
	}

	private containsKey(key: string) {
		return this.keys[key];
	}

	private convertToOption(item: ListItemForm): Option {
		var option = item.toObjectOption();

		var totalField = this.list.selectTotalField;
		if (totalField) {
			var f = item.findField(totalField);
			if (f) {
				option.values = {};
				option.values[totalField] = f.value;
			}
		}

		return option;
	}
}

export class List extends BoundObject implements Updateable, ExpressionSite {
	id: string;
	qualifier: string;
	label: string;
	displayAs: ListDisplayType;
	form: Form;
	items: ObservableArray<any>;
	prototype: ListPrototypeData;

	private prototypeForm: ObjectForm;
	private prototypeInitializing = ko.observable(false);
	private aggregates: { [id: string]: AggregateResults; } = {};
	aggregateCount: number;
	initialized = ko.observable(false);
	expressionError = ko.observable('');

	editMode: boolean;
	selections: ListSelections;
	selectMode: boolean;
	selectMultiple: boolean;
	selectionRequired: boolean;
	selectTotalField: string;
	selectPopup: boolean;
	private lastSelectedIndex = -1;

	linksDisabled: boolean;
	allSelected: Computed<boolean>;

	canEditItemsInline: boolean;
	canEditItems: boolean;
	canViewItems: boolean;
	canAddItems: boolean;
	canArchiveItems: boolean;
	canPasteItems: boolean;
	canAdd: boolean;
	isAddEnabledExpression: string;
	isAddEnabled: Subscribable<boolean>;
	canEdit: boolean;
	canRemove: boolean;
	canPaste: Subscribable<boolean>;

	canOrderItems: boolean;
	orderField: string;

	pageSize: number;
	defaultPageSize: number;
	autoSearch: boolean;
	includeArchived: boolean;

	hasChanges: Subscribable<boolean>;
	shouldPost: Subscribable<boolean>;
	isValid: Subscribable<boolean>;
	error: Subscribable<string>;
	customError = ko.observable('');
	errors: { [id: string]: string; };

	saveItemsVisible: Subscribable<boolean>;

	isEmpty: Computed<boolean>;
	showControl: Computed<boolean>;
	activeUrl: string;
	activeCreateArgument: string;
	contextFormUrl: string;

	isUpdating: Observable<boolean>;
	isBusy: Subscribable<boolean>;
	updateError: Observable<string>;

	relationship: string;
	relationshipTarget: ObjectForm;

	sortBy: Observable<string>;
	sortDescending: Observable<boolean>;
	currentSort: Computed<string>;
	modelIdForSortBy: Subscribable<string>;
	modelIdForSortDescending: Subscribable<string>;

	filter: FilterForm;
	pager: Pager;
	private loadUrl: string;
	private saveUrl: string;
	private exportUrl: string;
	private pasteUrl: string;
	onRequest: (request: ListRequest) => void;
	updateHistory: boolean;
	onLoad: boolean;
	queryId: number;

	displayTemplate: string;
	editTemplate: string;
	removedTemplate: string;

	editUrl: string;
	viewUrl: string;
	cloneUrl: string;

	expressionsEnabled: Subscribable<boolean>;

	isPasteActive: Observable<boolean>;

	select(command: Command) {
		if (this.canSelect()) {
			if (this.selectPopup) {
				var selections = this.selections.items();

				command.command = 'popupCallback';
				var ids = selections.map(s => s.id);
				command.argument = this.selectMultiple ? ids : ids[0];
			}

			this.form.save(command);
		}
	}

	cancelSelect(command: Command) {
		if (this.selectPopup) {
			command.command = 'popupCallback';
			command.argument = null;
		}

		this.form.cancel(command);
	}

	canSelect(): boolean {
		return this.selectMode && (this.selections.count() > 0 || !this.selectionRequired);
	}

	constructor(data: any, relationshipTarget?: ObjectForm, applyListItems?: boolean) {
		super();

		this.expressionsEnabled = (relationshipTarget && relationshipTarget.enableExpressions) || ko.observable(true);

		this.label = data.label;
		this.displayAs = data.displayAs;
		this.qualifier = data.qualifier || '';

		this.editUrl = data.editUrl;
		this.viewUrl = data.viewUrl;
		this.cloneUrl = data.cloneUrl;

		this.loadUrl = data.loadUrl || null;
		this.saveUrl = data.saveUrl || null;
		this.exportUrl = data.exportUrl || null;
		this.pasteUrl = data.pasteUrl || null;
		this.activeUrl = data.activeUrl || null;
		this.activeCreateArgument = data.activeCreateArgument || null;
		this.contextFormUrl = data.contextFormUrl || null;
		this.relationship = data.relationship || null;
		this.relationshipTarget = relationshipTarget || null;
		this.updateHistory = true;

		this.isUpdating = ko.observable(false);
		this.updateError = ko.observable<string>(null);

		this.selectMode = data.selectMode === true;
		this.selectMultiple = data.selectMultiple === true;
		this.selectionRequired = data.selectionRequired === true;
		this.selectTotalField = data.selectTotalField;
		this.selectPopup = data.selectPopup === true;
		this.linksDisabled = this.selectMode;

		this.canEditItemsInline = data.canEditItemsInline === true;
		this.canEditItems = data.canEditItems === true;
		this.canViewItems = data.canViewItems === true;
		this.canAddItems = data.canAddItems === true;
		this.canPasteItems = data.canPasteItems === true;
		this.isAddEnabledExpression = data.isAddEnabledExpression || null;
		this.canArchiveItems = (data.canArchiveItems != false);
		this.canAdd = this.canAddItems && !this.selectMode;
		this.isAddEnabled = ko.observable(this.canAdd);
		this.canPaste = ko.observable(this.canPasteItems);
		this.canEdit = this.canEditItemsInline;
		this.canRemove = this.canArchiveItems && !this.selectMode;
		this.editMode = this.canEditItemsInline || this.canAdd;
		this.pageSize = data.pageSize || 20;
		this.defaultPageSize = data.defaultPageSize || this.pageSize;
		this.autoSearch = data.autoSearch;
		this.includeArchived = data.includeArchived;
		this.canOrderItems = data.canOrderItems === true;
		this.orderField = data.orderField;
		this.onLoad = data.onLoad != false;

		this.pager = new Pager(this, data);
		this.queryId = data.queryId || null;

		this.selections = new ListSelections(this);

		this.applyErrors(data.errors || {});

		this.prototype = data.prototype;

		this.items = ko.observableArray();
		this.hasChanges = ko.computed(() => _.some(this.items(), (item: ObjectForm) => item.state() !== 'unmodified'));

		const postIfUnmodified = data.postIfUnmodified === true;
		this.shouldPost = ko.computed(() => postIfUnmodified || this.hasChanges() || (this.selectMode && this.selections.count()));

		if (data.items && applyListItems !== false) {
			this.applyListItems(data);
		}

		this.sortBy = ko.observable(data.sortBy || '');
		this.sortDescending = ko.observable(data.sortDescending || false);
		this.currentSort = ko.computed(() => {
			var sort = this.sortBy();
			if (sort && this.sortDescending()) sort = '-' + sort;
			return sort;
		});

		this.modelIdForSortBy = koDeferredComputed(() => this.qualifyId('SortBy'));
		this.modelIdForSortDescending = koDeferredComputed(() => this.qualifyId('SortDescending'));

		this.filter = data.filter ? new FilterForm(data.filter, this) : null;

		this.displayTemplate = data.displayTemplate;
		this.editTemplate = data.editTemplate;
		this.removedTemplate = data.removedTemplate;

		this.items.subscribe(this.reindex);

		this.allSelected = ko.computed({
			read: () => {
				return this.selections.items().length
					&& _.every(this.items(), (item: ListItemForm) => item.isSelected());
			},
			write: (newValue: boolean) => {
				if (newValue) {
					this.selectCurrentPage();
				}
				else {
					this.deselectCurrentPage();
				}
			}
		});

		this.isEmpty = ko.computed(() => this.items().length === 0 && !data.html);
		this.showControl = ko.computed(() => !this.isEmpty() || this.displayAs !== 'Grid');
		this.isValid = ko.computed(() => !this.customError() && _.every(this.items(), (item: ObjectForm) => item.isValid()));
		this.error = ko.computed(() => this.isValid() ? null : this.customError() || settings.strings.listItemsNotValid);

		if (data.displaySaveItems) {
			this.saveItemsVisible = ko.computed(() => this.hasChanges() && (this.pager.isVisible() || this.selectMode));
		}
		else {
			this.saveItemsVisible = ko.observable(false);
		}

		this.pager.offset.subscribe(() => this.load());
		this.pager.pageSize.subscribe(() => {
			if (this.pager.pageCount() <= 1 && this.pager.offset() != 0) {
				this.pager.offset(0);
			}
			else {
				this.load();
			}
		});

		this.isBusy = ko.computed(() => {
			if (this.isUpdating()) {
				return true;
			}
			else {
				return _.some(this.items(), (i: ListItemForm) => {
					return i.editMode && i.isBusy();
				});
			}
		});

		if (this.selectMultiple) {
			if (this.selectPopup) {
				//get current selections from the calling page
				var selections = getInputArgument();

				if (selections) {
					this.selections.selectOptions(selections);
				}
			}
			else if (data.selectedIds && data.selectedIds.length) {
				this.loadSelections(data.selectedIds);
			}
		}

		this.isPasteActive = ko.observable(false);
	}

	initialiseStatusExpressions() {
		this.isAddEnabled(this.canAdd);

		if (this.canAdd) {
			this.isAddEnabled = this.relationshipTarget.initialiseStatusExpression(this, "isAddEnabled", this.isAddEnabledExpression, this.isAddEnabled);
		}
	}

	qualifyId(id: string) {
		return this.shouldPost()
			? this.qualifier ? this.qualifier + '.' + id : id
			: null;
	}

	validate(): boolean {
		this.updateError(null);

		for (let item of this.items()) {
			item.validate();
		}

		return this.isValid();
	}

	public clearCustomErrors() {
		this.customError('');

		_.each(this.items(), (item: ObjectForm) => {
			item.errors.removeAll();
			item.clearCustomListErrors();
		});
	}

	showContextForm(item: ListItemForm, callback: (ObjectForm) => void) {
		if (!this.contextFormUrl) return;

		showModalPage({
			url: this.contextFormUrl,
			callback: (contextForm) => {
				if (contextForm) {
					callback(contextForm);
				} else {
					return item;
				}
			}
		});
	}

	beginPaste() {
		this.isPasteActive(true);
	}

	paste(data: string) {
		if (!this.pasteUrl) {
			return;
		}

		this.isUpdating(true);
		const rows = parseExcelHtml(data);

		if (rows) {
			let request: ListRequest = {
				url: this.pasteUrl,
				data: {
					data: rows
				}
			};

			this.onRequest && this.onRequest(request);

			jQuery.post(
				request.url,
				request.data
			)
			.fail((xhr) => {
				this.updateError(xhr.responseText)
			})
			.always(() => {
				this.isUpdating(false);
			});
		}
	}

	append(form: ListItemForm) {
		if (this.initialized()) {
			this.items.push(form);
		}
		else {
			this.items([form]);
			this.initialized(true);
		}
	}

	push(callback?: (form: ListItemForm) => void) {
		if (this.canAddItems) {
			var form = this.createForm();

			if (_.isFunction(callback)) {
				callback(form);
			}

			if (this.contextFormUrl) {
				this.showContextForm(form, (contextForm) => {
					form.mergeValues(contextForm);
					this.append(form);
				});
			}
			else {
				this.append(form);
			}
		}
	}

	selectAll() {
		if (!(this.selectMode && this.selectMultiple)) return;
		if (this.isUpdating()) return;

		if (this.pager.pageCount() > 1) {
			if (this.pager.total() > settings.maximumListPageSize) {
				if (!confirm(settings.strings.listSelectionLimited)) return;
			}

			this.loadSelections();
		}
		else {
			this.selectCurrentPage();
		}
	}

	selectItem(item: ListItemForm, select: boolean, selectRange: boolean = false) {
		var index = item.index();

		if (this.selectMultiple && selectRange) {
			var lower = Math.min(this.lastSelectedIndex, index);
			var upper = Math.max(this.lastSelectedIndex, index);

			var items = this.items().filter((x, i) => (i >= lower && i <= upper));

			if (select) {
				this.selections.selectMultiple(items);
			}
			else {
				this.selections.removeMultiple(items);
			}
		}
		else {
			if (select) {
				this.selections.select(item);
			}
			else {
				this.selections.remove(item);
			}
		}

		this.lastSelectedIndex = index;
	}

	resetRangeSelection() {
		this.lastSelectedIndex = 0;
	}

	whenIdle(callback: () => any) {
		return ExpressionLookup.whenIdle(this.isBusy, callback);
	}

	private loadSelections(ids?: Number[]) {
		if (!(this.selectMode && this.selectMultiple)) return;
		if (!this.loadUrl) return;
		if (this.isUpdating()) return;

		this.isUpdating(true);
		this.updateError(null);

		var request = this.buildRequest(this.loadUrl, 0, settings.maximumListPageSize);
		request.data.Ids = ids;

		if (this.onRequest) this.onRequest(request);

		jQuery
			.post(
			request.url,
			request.data
			)
			.done((result, status, xhr) => {
				this.selections.selectOptions(
					_.map(result.items, (item: ListItemData) => {
						var option: Option = {
							id: this.listItemDataId(item),
							text: item.displayName
						};

						if (this.selectTotalField) {
							option.values = {};
							option.values[this.selectTotalField] = this.listItemDataField(item, this.selectTotalField);
						}

						return option;
					})
				);

				this.selections.refresh();
			})
			.fail((xhr, status, error) => {
				this.updateError(xhr.responseText)
			})
			.always(() => {
				this.isUpdating(false);
			});
	}

	private selectCurrentPage() {
		if (!(this.selectMode && this.selectMultiple)) return;

		this.selections.selectMultiple(this.items());
		this.resetRangeSelection();
	}

	private deselectCurrentPage() {
		if (!(this.selectMode && this.selectMultiple)) return;

		this.selections.removeMultiple(this.items());
		this.resetRangeSelection();
	}

	private listItemDataId(item: ListItemData) {
		var idProperty = (this.prototype && this.prototype.idProperty) || ObjectForm.idProperty;
		return item[idProperty] || item.id;
	}

	private listItemDataField(item: ListItemData, fieldId: string): any {
		if (ObjectForm.isMetaField(fieldId)) {
			const result = item[fieldId];

			if (result !== undefined) {
				return result;
			}

			return item[fieldId.charAt(1).toLowerCase() + fieldId.substring(2)];
		}
		else {
			if (item.values) {
				return item.values[fieldId];
			}

			const result = item[fieldId];

			if (result !== undefined) {
				return result;
			}

			return item[fieldId.charAt(0).toLowerCase() + fieldId.substring(1)];
		}
	}

	createForm(item?: ListItemData, index?: number) {
		index = index || this.items().length;

		var data = <ListPrototypeData>$.extend(true, {}, this.prototype);
		data.activeUrl = this.activeUrl;
		data.linksDisabled = this.linksDisabled;

		if (_.isObject(item)) {
			data.dataId = this.listItemDataId(item);
			data.formId = item.formId;
			data.key = item.key;
			data.parentKey = item.parentKey;
			data.displayName = item.displayName;
			data.cloneUrl = item.cloneUrl || null;
			data.isLocked = item.isLocked || false;
			data.canRemove = item.canRemove;
			data.canEdit = item.canEdit;
			data.isArchived = item.isArchived || false;
			data.canView = item.canView;
			data.errors = item.errors || {};
			_.each(this.errors, (v, k?: string) => {
				var prefix = "[" + index + "].";
				if (k.indexOf(prefix) == 0) {
					data.errors[k.substr(prefix.length)] = v;
					this.errors[k] == null;
				}
			});
			data.activeOperations = item.activeOperations || null;

			_.each(data.fields, (field, id?) => {
				field.value = this.listItemDataField(item, id);
			});

			return new ListItemForm(data, index, this);
		}
		else {
			var relationship = this.relationship && this.relationshipTarget;
			if (relationship) {
				var field = data.fields[this.relationship];
				if (field) {
					field.value = this.relationshipTarget.toObjectOption();
				}
			}

			if (this.activeCreateArgument) {
				data.activeCreateArgument = this.activeCreateArgument;
			}

			var form = new ListItemForm(data, index, this, true);

			if (relationship) {
				for (let field of form.fields.scalar) {
					if (field.computedValueTargetsParent && field.computedValue) {
						field.computedValue.notifySubscribers(field.computedValue());
					}
				}
			}

			return form;
		}
	}

	remove(item: ListItemForm) {
		switch (item.state()) {
			case 'removed':
				break;

			case 'added':
				this.items.remove(item);
				break;

			default:
				if (this.canRemove) {
					item.isRemoved(true);
				}
				break;
		}
	}

	rowTemplate(item: ListItemForm) {
		if (item.isRemoved()) {
			return this.removedTemplate;
		}
		else if (item.editMode) {
			return this.editTemplate;
		}
		else {
			return this.displayTemplate;
		}
	}

	private reindex() {
		_.each(this.items(), (item: ListItemForm, i?: number) => item.index(i));
	}

	assertNoChanges(): boolean {
		if (this.hasChanges()) {
			alert(settings.strings.listHasChanges);
			return false;
		}
		else if (this.selectMode) {
			if (_.some(this.items(), (item: ListItemForm) => item.isSelected() && !item.isValid())) {
				alert(settings.strings.selectionsAreNotValid);
				return false;
			}
		}
		return true;
	}

	private buildRequest(url: string, offset?: number, pageSize?: number): ListRequest {
		if (!_.isNumber(offset)) {
			offset = this.pager.offset();
		}

		if (!_.isNumber(pageSize)) {
			pageSize = this.pager.pageSize();
		}

		var data = {
			o: offset || "",
			n: pageSize || "",
			s: this.currentSort()
		};

		if (this.filter) this.filter.snapshot(data);

		var request: ListRequest = {
			url: url,
			data: data
		};

		if (this.onRequest) this.onRequest(request);

		return request;
	}

	applyErrors(errors: { [index: string]: string; }) {
		var e = errors[''];
		if (e) {
			this.customError(e);
		};

		if (this.initialized()) {
			_.each(this.items(), (item: ListItemForm, index?) => {
				item.applyErrors(errors, `[{index}].`);
			});
		}
		else {
			this.errors = errors;
		}
	}

	applyListItems(result) {
		this.pager.total(result.total);

		if (this.items().length) {
			this.items.removeAll();
		}

		this.items(
			_.map(result.items, (item: ListItemData, i?: number) => this.createForm(item, i))
		);

		this.initialized(true);
	}

	private applyResult(result: ListResult) {
		if (!result.error) {
			this.applyListItems(result);
			this.aggregateCount = result.total - this.aggregateItems(this.items(), 'count');
			this.aggregates = result.aggregates || {};

			_.each(this.aggregates, (item?: AggregateResults, key?: string) => {
				if (!_.isUndefined(item.sum)) {
					item.sum -= this.aggregateItems(this.items(), 'sum', key);
				}
			});
		}
		else {
			this.updateError(result.error);
			if (result.items) {
				var items = this.items();
				this.items(
					_.map(result.items, (item: ListItemData, i?: number) => item.errors ? this.createForm(item, i) : items[i])
				);
			}
		}
	}

	exportList() {
		if (!this.exportUrl) return;

		if (this.pager.total() > settings.exportPageSize) {
			if (!confirm(settings.strings.listExportLimited)) return;
		}

		var url = this.exportUrl;
		var request = this.buildRequest(url);
		request.data.o = null;
		request.data.n = null;

		var qs = this.filter ? this.filter.buildQueryParams(request.data).toString() : null;

		url = url + (url.indexOf("?") < 0 ? "?" : "&") + qs;

		window.location.href = url;
	}

	tryAutoSearch() {
		if (!this.autoSearch) {
			return;
		}

		if (this.filter) {
			this.filter.search();
		}
		else {
			this.load();
		}
	}

	load() {
		if (!this.assertNoChanges()) return;
		if (this.isBusy()) return;

		this.isUpdating(true);
		this.updateError(null);

		const request = this.buildRequest(this.loadUrl);
		const qs = this.filter
			? this.filter.mergeQueryParams(request.data, this.queryId)
			: new URLSearchParams(window.location.search);

		if (!this.loadUrl) {
			window.location.href = `?${qs.toString()}${window.location.hash}`;
			return;
		}
		else {
			if (this.updateHistory) {
				setQueryParameters(qs);
			}
		}

		if (this.onLoad === false) {
			this.isUpdating(false);

			return;
		}

		if (this.onRequest) this.onRequest(request);

		jQuery
			.post(
				request.url,
				request.data
			)
			.done((result, status, xhr) => {
				if (!this.initialized()) this.initialized(true);
				this.applyResult(result);
				this.selections.refresh();
			})
			.fail((xhr, status, error) => {
				if (xhr.status != 401) {
					this.updateError(xhr.responseText)
				}
			})
			.always(() => {
				this.isUpdating(false);
			});
	}

	save() {
		if (!this.saveUrl) return;
		if (!this.hasChanges()) return;
		if (this.isUpdating()) return;

		if (!this.validate()) {
			this.updateError(this.error());
			return;
		}

		this.isUpdating(true);
		this.updateError(null);

		var request = this.buildRequest(this.saveUrl);
		this.snapshot("items", request.data, true);

		if (this.onRequest) this.onRequest(request);

		jQuery.post(
			request.url,
			request.data
		)
			.done((result, status, xhr) => {
				this.applyResult(result);
			})
			.fail((xhr, status, error) => {
				this.updateError(xhr.responseText)
			})
			.always(() => {
				this.isUpdating(false);
			});
	}

	snapshot(qualifier: string, map, excludeTargets?: boolean) {
		_.each(this.items(), (item: ListItemForm) => {
			var itemMap: _.Dictionary<any> = {};
			item.snapshot(itemMap, excludeTargets);
			_.each(itemMap, (value, key?: string, object?: Object) => {
				map[(qualifier || '') + '[' + item.index() + '].' + key] = value;
			});
		});
	}

	revert() {
		//transient items are erased
		var added = _.filter(this.items(), (item: ObjectForm) => item.state() == 'added');
		this.items.removeAll(added);

		//persistent ones are reverted
		_.each(this.items(), (item: ObjectForm) => item.revert());

		//notify subscribers that the list has reverted which is needed for Gantt
		this.items.valueHasMutated();

		this.updateError(null);
	}

	sort(data: any, e: Event) {
		if (!this.assertNoChanges()) return;
		var id = $(e.target).attr('data-id');
		if (this.sortBy() == id) {
			this.sortDescending(!this.sortDescending());
		}
		else {
			this.sortDescending(false);
		}
		this.sortBy(id);
		this.load();
	}

	aggregateItems(items: any[], fn: string, target?: string): any {
		var result;
		switch (fn.toLowerCase()) {
			case 'sum':
			case 'count':
				result = 0;
				break;
			default:
				return null;
		}

		_.each(items, (item: ObjectForm) => {
			if (!this.includeArchived) {
				if (item.state() == 'removed') {
					return;
				}

				var f = item.findField('IsArchived');
				if (f && f.value() == true) {
					return;
				}
			}

			var targetValue = (item: ObjectForm) => {
				var f = item.lookupTarget(target);
				if (!f) return;

				var x = f.value();

				if (f instanceof BoolField) {
					return x ? 1 : 0;
				}

				if (f instanceof NumberField) {
					x = (<NumberField>f).round(x);
				}

				return x;
			}

			switch (fn.toLowerCase()) {
				case 'sum':
					var x = targetValue(item);
					if (NumberField.isValidNumber(x)) result += x;
					break;
				case 'count':
					result++;
					break;
			}
		});

		return result;
	}

	findPrototypeField(target: string) {
		if (!this.prototypeForm) {
			if (!this.prototypeInitializing()) {
				this.prototypeInitializing(true);
				this.prototypeForm = this.createForm(null, 0);
				this.prototypeInitializing(false);
			}
			else {
				return this.findPrototypeField(target);
			}
		}
		
		return this.prototypeForm.lookupTarget(target);
	}

	private findAggregate(target: string): AggregateResults {
		const f = this.findPrototypeField(target);
		if (!f) {
			return null;
		}

		return this.aggregates[f.id] || null;
	}

	getAggregateResult(resultField: Field, fn: string, target?: string): Number {
		fn = fn.toLowerCase();

		var aggregateFunction = (items) => {
			var result = null;

			switch (fn) {
				case 'sum':
					var a = this.findAggregate(target);
					if (a) {
						result = a[fn];
					}
					result = (result || 0) + this.aggregateItems(items, fn, target);
					break;

				case 'count':
					result = this.aggregateCount + this.aggregateItems(items, fn);
					break;
			}

			return result;
		}

		function trySubscribe(list: List):Number {
			if (fn != 'count') {
				var a = list.findPrototypeField(target);
				if (!a) return resultField.value.peek();
			}

			list.items.subscribe(aggregateFunction);

			if (list.initialized()) {
				//list has items, calculate the sum immediately
				return aggregateFunction(list.items());
			}
			else {
				//list is not initialized, just return the current value for now
				//the value will be re-evaluated when the list loads
				return resultField.value.peek();
			}	
		}

		if (!this.prototypeInitializing()) {
			return trySubscribe(this);
		}
		else {
			this.prototypeInitializing.subscribe(value => {
				if (!value) {
					trySubscribe(this);
				}
			})

			return resultField.value.peek();
		}
	}

	getGlobals() : ExpressionGlobals {
		return new ExpressionGlobals(this);
	}
}

export class ContextListForm extends ListForm {
	onInitialize() {
		var source = getInputArgument();

		if (source) {
			this.list().items(
				source.map((x, i) => this.list().createForm(x, i))
			);
		}
	}

	revert() {
		super.revert();

		if (this.list().canOrderItems && this.list().orderField) {
			this.list().load();
		}
	}

	save(command: Command) {
		command.command = 'popupCallback';
		command.argument = this;

		super.save(command);
	}
}

export class ListField extends List implements Field {
	id: string;
	key: string;
	modelId: Subscribable<string>;

	value: Observable<any[]>;

	isVisible = ko.observable(true);
	isDisabled = ko.observable(false);
	isRequired = ko.observable(false);
	expressionError = ko.observable('');

	linksDisabled: boolean;
	computedValueTargetsParent: boolean;

	onFocus: () => void;
	onBlur = () => { };

	constructor(data: any, public form: ObjectForm) {
		super(data.list, form, false);
		this.updateHistory = false;

		if (data.list.displaySaveItems) {
			this.saveItemsVisible = ko.computed(() => form.dataId() && this.hasChanges() && this.pager.isVisible());
		}

		this.id = data.id;
		this.key = data.key || null;
		this.label = data.label;

		this.canAdd = this.canAddItems && (form.canEdit || data.list.alwaysAppendable);
		this.canEdit = this.canEditItemsInline && form.editMode;
		this.canRemove = this.canArchiveItems;
		this.editMode = this.canAdd || this.canEdit || this.canRemove;
		this.canPaste = ko.computed(() => this.canPasteItems && form.dataId() > 0);

		this.linksDisabled = form.linksDisabled;

		if (data.list.items) {
			this.applyListItems(data.list);
		}

		this.value = this.items;

		this.onRequest = (request: ListRequest) => {
			$.extend(request.data, {
				i: form.dataId() || 0
			});
		};
	}

	focus() {
		this.onFocus && this.onFocus();

		if (this.initialized()) {
			return;
		}

		this.form.whenIdle(() => this.tryAutoSearch());
	}
}

interface PageItem {
	isCurrent: boolean;
	text: string;
	action?: () => void;
}

class Pager extends BoundObject {
	pageSize: Observable<number>;
	offset: Observable<number>;
	total = ko.observable(0);

	pageSizeOptions: number[] = [];

	modelIdForTotal: Subscribable<string>;
	modelIdForOffset: Subscribable<string>;

	start: Computed<number>;
	end: Computed<number>;
	pageCount: Computed<number>;
	isVisible: Subscribable<boolean>;
	items: Computed<any[]>;
	summary: Computed<string>;

	private currentIndex: Computed<number>;

	constructor(public list: List, data) {
		super();

		this.modelIdForTotal = koDeferredComputed(() => list.qualifyId("TotalCount"));
		this.modelIdForOffset = koDeferredComputed(() => list.qualifyId("Offset"));

		this.pageSize = ko.observable(list.pageSize);
		this.offset = ko.observable(data.offset || 0);

		this.pageCount = ko.computed(() =>
			Math.ceil(this.total() / this.pageSize() || 0)
		);

		this.isVisible = ko.computed(() =>
			this.total() > this.list.defaultPageSize
		);

		this.currentIndex = ko.computed(() => {
			var x = this.offset() / this.pageSize() || 0;
			return x > this.pageCount() ? 0 : x;
		});

		this.start = ko.computed(() =>
			this.pageSize() * this.currentIndex() + 1
		);

		this.end = ko.computed(() =>
			Math.min(this.start() + this.pageSize() - 1, this.total())
		);

		this.items = ko.computed(() => {
			if (!this.isVisible()) return [];

			var items: PageItem[] = [];
			var pageSize = this.pageSize();
			var pageCount = this.pageCount();
			var startIndex = 0;
			var currentIndex = this.currentIndex();
			var endIndex = pageCount;

			if (pageCount > 10) {
				startIndex = currentIndex - (currentIndex % 10);
				endIndex = startIndex + 10;
				if (endIndex > pageCount) endIndex = pageCount;
			}

			var pager = this;
			function makeItem(index: number, text?: string): PageItem {
				return {
					text: text || '' + (index + 1),
					isCurrent: index == currentIndex,
					action: (index == currentIndex) ? null : () => {
						if (pager.list.assertNoChanges()) {
							pager.offset(pageSize * index)
						}
					}
				};
			}

			if (startIndex > 0) {
				items.push(makeItem(0, 'first'));
				items.push(makeItem(startIndex - 1, '...'));
			}

			for (var i = startIndex; i < endIndex; i++) {
				items.push(makeItem(i));
			}

			if (endIndex < pageCount) {
				items.push(makeItem(endIndex, '...'));
				items.push(makeItem(pageCount - 1, 'last'));
			}

			return items;
		});

		this.summary = ko.computed(() =>
			format('Showing {0} to {1} of {2}', this.start(), this.end(), this.total())
		);

		this.buildPageSizeOptions();
	}

	changePageSize(pageSize) {
		if (this.list.assertNoChanges()) {
			this.pageSize(pageSize);
		}
	}

	private buildPageSizeOptions() {
		this.pageSizeOptions = [this.list.defaultPageSize, 50, 100]

		if (!this.list.editMode) {
			this.pageSizeOptions.push(200);
		}

		this.pageSizeOptions
			//if the index differs from i, x must be a duplicate
			.filter((x, i, a) => a.indexOf(x) === i)
			.sort((x, y) => x - y);
	}
}

interface ObjectPropertyRequest {
	id: string;
	state: 'pending' | 'active'
	formId: number;
	objectId: number;
	property: string;
	onSuccess: Function;
	onFail: Function;
};

class ObjectPropertyTool {
	private static requests: { [index: string]: ObjectPropertyRequest; } = {};
	private static pendingCount = 0;
	private static timeout: number;

	static lookup(targetId: string, object: Option, property: string, begin: Function, success: Function, fail: Function): any {
		if (!object || !property) {
			return null;
		}

		if (property.toLowerCase() === ObjectForm.idProperty) {
			return object.id;
		}

		if (object.values && (property in object.values)) {
			return object.values[property];
		}

		var callback = target => {
			if (!target) {
				return null;
			}

			if (!property) {
				return target;
			}

			const index = property.indexOf(".");
			let subProperty = null;
			if (index >= 0) {
				if (index < property.length) {
					subProperty = property.slice(index + 1);
				}
				property = property.slice(0, index);
			}

			if (!(target.values) || !(property in target.values)) {
				return null;
			}

			const value = target.values[property];

			if (subProperty) {
				return ObjectPropertyTool.lookup(`${targetId}.${property}`, value, subProperty, begin, success, fail);
			}
			else {
				return value;
			}
		}

		if (object.target) {
			return callback(object.target);
		}
		else {
			if (object.targetFormId && object.id) {
				begin();

				ObjectPropertyTool.getProperties(
					targetId,
					object.targetFormId,
					object.id,
					property,
					target => {
						object.target = target;
						success();
						callback(target);
					},
					fail
				);

				return null;
			}

			return null;
		}
	}

	static getProperties(targetId: string, formId: number, objectId, property: string, success: Function, fail: Function) {
		const id = `${targetId}.${formId}.${property}`;

		if (ObjectPropertyTool.requests[id]) {
			return;
		}

		if (ObjectPropertyTool.timeout) {
			clearTimeout(ObjectPropertyTool.timeout);
		}

		ObjectPropertyTool.requests[id] = {
			id: id,
			state: 'pending',
			formId: formId,
			objectId: objectId,
			property: property,
			onSuccess: success,
			onFail: fail
		};

		ObjectPropertyTool.pendingCount++;
		const batchSize = 50;

		if (ObjectPropertyTool.pendingCount >= batchSize) {
			ObjectPropertyTool.fulfillRequests();
		}
		else {
			ObjectPropertyTool.timeout = window.setTimeout(ObjectPropertyTool.fulfillRequests, 50);
		}
	}

	private static fulfillRequests() {
		let items = [];

		for (const id in ObjectPropertyTool.requests) {
			let request = ObjectPropertyTool.requests[id];

			if (request.state === 'pending') {
				ObjectPropertyTool.pendingCount--;
				request.state = 'active';

				items.push({
					id: request.id,
					formId: request.formId,
					objectId: request.objectId,
					property: request.property
				});
			}
		}

		if (items.length > 0) {
			console.log("%i property load requests are pending", items.length);

			$.ajax({
				url: appUrl("~/reference/properties/"),
				type: "POST",
				contentType: "application/json",
				data: JSON.stringify(items),
				converters: {
					"text json": result => {
						return JSON.parse(result, (_, value) => {
							if (typeof value === 'string') {
								var maybeDate = parseIso8601Date(value);

								if (maybeDate !== null) {
									return maybeDate;
								}
							}

							return value;
						});
					}
				},
				success: result => {
					for (const id in result) {
						const request = ObjectPropertyTool.requests[id];

						if (request && request.onSuccess) {
							request.onSuccess(result[id]);
						}

						delete ObjectPropertyTool.requests[id];
					}
					console.log("%i property load requests have been fulfilled", items.length);
				},
				error: (_, __, error: string) => {
					for (let i = 0; i < items.length; i++) {
						const id = items[i].id;
						const request = ObjectPropertyTool.requests[id];

						if (request && request.onFail) {
							request.onFail(error);
						}

						delete ObjectPropertyTool.requests[id];
					}
					console.error("some property load requests failed", error);
				}
			});
		}
	}
}

interface SequenceValueResult {
	raw: number;
	formatted: string;
}

class SequenceNumberTool {
	private static readonly results = new Map<string, SequenceValueResult>();

	static Next(id: string, sequence: string, begin: Function, success: Function, fail: Function): SequenceValueResult {
		var key = `${id}:${sequence}`;
		var result = SequenceNumberTool.results.get(key);

		if (result || result === null) {
			return result;
		}

		begin();

		$.ajax({
			url: appUrl("~/expression/sequence/"),
			type: "POST",
			contentType: "application/json",
			data: JSON.stringify({name: sequence})
		})
		.done((result) => {
			SequenceNumberTool.results.set(key, result);
			success(result);
		})
		.fail((xhr) => {
			fail(xhr.responseText);
		})
	}
}

export class ParameterForm extends ObjectForm {
	private respondToQueryChanges = true;

	hasUserFilter: Observable<boolean>;

	constructor(data: any) {
		super(data);

		const initialParams = new URLSearchParams(window.location.search);

		for (const field of this.scalarFields()) {
			const initialValue = initialParams.get(field.id);
			if (initialValue) {
				field.parse(initialValue);
			}
		}

		this.hasChanges = ko.pureComputed(() => false);

		handleQueryChange(args => {
			const { current } = args;

			if (this.respondToQueryChanges) {
				for (const [name, value] of current) {
					const field = this.fields.map[name];
					if (field instanceof ScalarField) {
						field.parse(value);
					}
				}
			}
		});

		this.hasUserFilter = ko.observable(data.hasUserFilter ?? false);
	}

	search() {
		if (this.validate()) {
			this.setParams(this.collectParams());
		}
	}

	clear() {
		let params = this.collectParams();

		for (const x in params) {
			params[x] = undefined;
		}

		this.setParams(params);

		for (const field of this.scalarFields()) {
			field.parse(undefined);
			field.error(undefined);
			ko.tasks.runEarly();
			field.isValid(true);
		}
	}

	saveUserFilter() {
		const filter = this.collectParams();
		saveUserFilter(UserFilterType.ParameterForm, this.formId, filter, () => this.hasUserFilter(true));
	}

	clearUserFilter() {
		clearUserFilter(UserFilterType.ParameterForm, this.formId, () => this.hasUserFilter(false));
	}

	private setParams(params: Record<string, string>) {
		try {
			this.respondToQueryChanges = false;
			let result = new URLSearchParams(window.location.search);

			for (const [key, value] of Object.entries(params)) {
				if (value) {
					result.set(key, value);
				}
				else {
					result.delete(key);
				}
			}

			setQueryParameters(result);
		}
		finally {
			this.respondToQueryChanges = true;
		}
	}

	private collectParams() {
		let result: Record<string, string> = {}

		for (const field of this.scalarFields()) {
			field.snapshot(result, true);
		}

		return result;
	}

	private *scalarFields() {
		for (const field of this.fields.user) {
			if (field instanceof ScalarField) {
				yield field;
			}
		}
	}
}
