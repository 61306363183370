import { raise as raiseQueryChange } from '../areas/main/events/queryChanged';

export function getQueryParameter(name: string): string | null {
	const params = new URLSearchParams(window.location.search);
	return params.get(name);
};

export function setQueryParameter(name: string, value: any) {
	const params = new URLSearchParams(window.location.search);

	if (value === undefined || value === null || value === '') {
		params.delete(name);
	}
	else {
		params.set(name, value);
	}

	setQueryParameters(params);
}

export function setQueryParameters(params: URLSearchParams) {
	const previous = new URLSearchParams(window.location.search);
	const url = `?${params.toString()}${window.location.hash}`;
	window.history.replaceState(null, '', url);

	raiseQueryChange(params, previous);
}

export function rebuildUrlQuery(url: string, values: Record<string, string>) {
	const [start, search] = url.split('?');

	let params = { ...parseQuery(search) };

	for (const [key, value] of Object.entries(values)) {
		if (value === undefined) {
			delete params[key];
		}
		else {
			params[key] = value;
		}
	}

	return `${start}?${formatQuery(params)}`;
}

function parseQuery(query: string): Record<string, string> {
	const params = new URLSearchParams(query);
	const result: Record<string, string> = {};

	for (const [name, value] of params) {
		result[name] = value;
	}

	return result;
}

function formatQuery(query: Record<string, string>) {
	if (!query) {
		return '';
	}

	const params = new URLSearchParams(query);
	return params.toString();
}
