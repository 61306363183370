export default function loadScript(src: string) {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = src;

		script.addEventListener('load', resolve);
		script.addEventListener('error', e => reject(e));
		script.addEventListener('abort', () => reject('Script loading aborted'));

		try {
			document.head.appendChild(script);
		}
		catch (e) {
			reject(e)
		}
	});
}
