import jQuery from 'jquery';

const id = 'konfig:query-changed';

export interface Args {
	current: URLSearchParams;
	previous: URLSearchParams | undefined
}

export function raise(params: URLSearchParams, previous: URLSearchParams) {
	jQuery(window).trigger(id, { current: params, previous });
}

export function handle(handler: (args: Args) => void) {
	jQuery(window).on(id, (_, args) => {
		handler(args as Args);
	});
}

export function paramsWhichDiffer(args: Args): Set<string> {
	let { current, previous } = args;

	if (previous) {
		let result = new Set<string>();

		for (const key of new Set([...current.keys(), ...previous.keys()])) {
			if (current.get(key) !== previous.get(key)) {
				result.add(key);
			}
		}

		return result;
	}
	else {
		return new Set(current.keys());
	}
}
