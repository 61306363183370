import appUrl from '../../../util/appUrl';
import { confirm } from '../../../components/notify';
import { error as showError, success as showSuccess } from '../../notify';
import { settings } from '../../../areas/main/config';

export enum UserFilterType {
	List,
	Report,
	ParameterForm
}

export function save(type: UserFilterType, id: number, filter: any, onSuccess: Function) {
	$.ajax({
		url: appUrl('~/User/SaveFilter'),
		method: 'post',
		data: { id, type, filter: JSON.stringify(filter) },
		dataType: 'json',
		success: () => {
			showSuccess(settings.strings.userFilterSaved);
			onSuccess();
		},
		error: () => showError(settings.strings.userFilterSaveError)
	});
}

export function clear(type: UserFilterType, id: number, onSuccess: Function) {
	confirm(settings.strings.confirmClearUserFilter)
		.then(result => {
			if (result) {
				$.ajax({
					url: appUrl('~/User/ClearFilter'),
					method: 'post',
					data: { id, type },
					dataType: 'json',
					success: () => {
						showSuccess(settings.strings.userFilterCleared);
						onSuccess();
					},
					error: () => showError(settings.strings.userFilterClearError)
				});
			}
		});
}
